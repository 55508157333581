import styled from 'styled-components'
import { MediumText } from 'components/common/text'
import { Row } from 'components/common/layout'

export enum BadgeColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  BLUE = 'blue',
  GRAY2 = 'gray2',
  RED = 'red',
  GREEN = 'green',
  LIGHT_GREEN = 'lightGreen',
  ORANGE = 'orange',
  ALERT = 'alert',
}

export const Badge = styled(MediumText)<{
  color?: BadgeColor
  filled?: boolean
}>`
  display: inline-block;
  padding: 4px 6px;
  width: fit-content;
  white-space: nowrap;
  font-size: 14px;
  color: ${(p) =>
    p.filled
      ? p.theme.color.white1
      : p.theme.color[p.color || BadgeColor.PRIMARY]};
  border: 1px solid ${(p) => p.theme.color[p.color || BadgeColor.PRIMARY]};
  border-radius: 4px;
  background-color: ${(p) =>
    p.filled
      ? p.theme.color[p.color || BadgeColor.PRIMARY]
      : p.theme.color.white1};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: 12px;
    padding: 2px 4px;
  }
`

export const AlertBadge = styled(Row)<{
  size: number
  top?: number
  right?: number
  left?: number
}>`
  min-width: ${(p) => p.size}px;
  padding: 0 3px;
  height: ${(p) => p.size}px;
  ${(p) => (p.top || p.right || p.left) && `position: absolute`};
  ${(p) => p.top && `top: ${p.top}px`};
  ${(p) => p.left && `left: ${p.left}px`};
  ${(p) => p.right && `right: ${p.right}px`};
  justify-content: center;
  border-radius: 100px;
  border: 1px solid ${(p) => p.theme.color.white1};
  font-size: ${(p) => p.theme.fontSize.small};
  color: ${(p) => p.theme.color.white1};
  background-color: ${(p) => p.theme.color.primary};
  white-space: nowrap;
`

export const NewBadge = styled(Badge).attrs({ filled: true })`
  padding: 2px 6px;
  font-size: 10px;
  border-radius: 16px;
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
`
