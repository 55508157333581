import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'components/common/modal'
import { DataPicker } from 'components/common/data-picker'
import { useBaseInfo, useScoutMyRequest, useUser } from 'apis/hooks'
import { BaseText, SemiBoldText } from 'components/common/text'
import { Column } from 'components/common/layout'
import { WithIdNameParentId } from 'types/common'
import { putAccountsMe, putRequestScoutRequestMe } from 'apis/request'
import { CategorySelector } from 'components/misc/selector/category-selector'
import { requestToast } from 'components/common/toast'
import { mutate } from 'swr'
import { useStores } from 'stores/globals'
import { withLoadingCheck } from 'utils/handlers'
import { POSITION_SELECTOR_LIMIT } from 'infra/constants'

const DEPLOY_TIME = '2025-01-22T10:03:00.342888+09:00'

export const PositionSelectorModal: React.FC = () => {
  const { loginStore } = useStores()
  const { data: user } = useUser()
  const { data: baseInfo } = useBaseInfo()
  const { data: myProfile } = useScoutMyRequest()
  const [isOpen, setIsOpen] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const [selectedPositions, setSelectedPositions] = useState<
    WithIdNameParentId[]
  >([])
  useEffect(() => {
    if (!baseInfo || !user || isInitialized) return
    setSelectedPositions(
      baseInfo?.positionTypes.filter((item) =>
        user?.interestedPositionTypes.map((item) => item.id).includes(item.id),
      ) || [],
    )
    setIsInitialized(true)
  }, [baseInfo, user])

  if (!user || !baseInfo || !isOpen || !myProfile) return null

  const isScoutRequestCreatedAfterReleaseDate =
    new Date(myProfile.createdAt).getTime() > new Date(DEPLOY_TIME).getTime()
  const isScoutRequestUpdatedAfterReleaseDate =
    new Date(myProfile.updatedByUserAt).getTime() >
    new Date(DEPLOY_TIME).getTime()
  if (
    loginStore.isFake ||
    isScoutRequestCreatedAfterReleaseDate ||
    isScoutRequestUpdatedAfterReleaseDate
  )
    return null

  const save = async () => {
    if (selectedPositions.length === 0) {
      throw '최소 한개의 직군을 선택해주세요'
    }
    const selectedPositionIds = selectedPositions.map((item) => item.id)
    await requestToast(
      putAccountsMe({
        interestedPositionTypes: selectedPositionIds,
      }),
      {
        loading: '저장중',
        success: '직군이 내 프로필에 저장되었습니다.',
      },
    )
    await putRequestScoutRequestMe({ removedCareers: [] })
    await mutate('/accounts/me')
    mutate('/scouts/requests/me')
    setIsOpen(false)
  }

  return (
    <Modal width={500} disableCloseBtn disableBackgroundClick>
      <DataPicker
        data={baseInfo.positionTypes}
        selectedData={selectedPositions}
        onClose={() => withLoadingCheck(isLoading, setIsLoading, save)}
        onDelete={(item) => {
          if (!item.parentId) {
            setSelectedPositions((positions) =>
              positions.filter((position) => position.parentId !== item.id),
            )
          } else {
            setSelectedPositions((positions) =>
              positions.filter((position) => position.id !== item.id),
            )
          }
        }}
        onReset={() => setSelectedPositions([])}
      >
        <Column style={{ gap: 40 }}>
          <Column style={{ gap: 8, textAlign: 'center' }}>
            <MainText>직군 확장 업데이트 소식</MainText>
            <SubText>{`확장된 직군 설정으로\n딱 맞는 스카우트 제안을 받아볼 수 있어요`}</SubText>
          </Column>
          <CategorySelector
            data={baseInfo.positionTypes}
            selectedData={selectedPositions}
            onChange={(locations) => {
              if (locations.length > POSITION_SELECTOR_LIMIT)
                return alert('최대 5개까지 선택 가능합니다.')
              setSelectedPositions(locations)
            }}
            isFilter
          />
        </Column>
      </DataPicker>
    </Modal>
  )
}

const MainText = styled(SemiBoldText)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h4};
  }
  font-size: ${(p) => p.theme.fontSize.h3};
`

const SubText = styled(BaseText)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    line-height: 22px;
    font-size: ${(p) => p.theme.fontSize.desc};
  }
  line-height: 1.5;
  color: ${(p) => p.theme.color.black4};
  font-size: ${(p) => p.theme.fontSize.h5};
`
