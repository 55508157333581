//component hook 처리

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIsClient, useMediaQuery } from 'usehooks-ts'
import { debounce, throttle } from 'lodash-es'
import { useStores } from 'stores/globals'
import { Key, mutate } from 'swr'
import { emitter } from 'infra/event-emitter'
import { PreferenceStore } from 'stores/scouts/preference'
import {
  ApplicationDetail,
  ConditionForm,
  DashboardApplicationDetail,
  DashboardScoutProposalDetail,
  RequestFormOptions,
  ScoutProposalDetail,
  WithIdNameParentId,
} from 'types/common'
import { theme } from 'infra/theme'
import Cookies from 'js-cookie'
import { COOKIE_KEY_ADMIN } from 'infra/constants'
import { ChatRoomStore } from 'stores/chat-rooms/chat-room'
import { useRouter } from 'next/router'

export const useIsDesktopCSR = () => useMediaQuery(theme.deviceSize.desktop)

// NOTE(JUNSEULGI): https://chana.tistory.com/entry/Nextjs-mobile-responsive-react-responsive
export const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = useState(true)
  const matches = useMediaQuery(theme.deviceSize.desktop)
  useEffect(() => {
    setIsDesktop(matches)
  }, [matches])
  return isDesktop
}

// https://www.carlrippon.com/using-lodash-debounce-with-react-and-ts/
export const useDebounce = (timer: number, callback: Function) => {
  return useRef(
    debounce((...props) => {
      callback(...props)
    }, timer),
  ).current
}

export const useThrottle = (timer: number, callback: Function) => {
  return useRef(
    throttle((...props) => {
      callback(...props)
    }, timer),
  ).current
}

// NOTE(gogo): 이 값을 사용하는 곳은 현재 observer 를 써야 함
// TODO(gogo): mobx 상태를 훅 값으로 받아서 쓸 수 있게 만들기
export const useIsLoggedIn = () => {
  const { loginStore } = useStores()
  const isClient = useIsClient()
  // hydration mismatch 문제를 해결하기 위해 isClient 를 체크한다
  // => 최초 클라이언트 렌더 이후에 isClient 가 true 로 바뀜
  // hydration mismatch 란: SSR 내용과 CSR 내용이 서로 다른 경우 에러 발생
  return isClient ? loginStore._isLoggedIn : false
}

export const useIsLoggedInAdmin = () => {
  const isClient = useIsClient()
  return isClient ? !!Cookies.get(COOKIE_KEY_ADMIN) : false
}

export const useIsLoggedInStartup = () => {
  const { loginStore } = useStores()
  const isClient = useIsClient()
  return isClient ? loginStore._isLoggedInStartup : false
}

export const useInfiniteScroll = (countUp: () => void) => {
  const observer = useRef<IntersectionObserver | null>(null)
  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          countUp()
          observer?.current?.disconnect()
        }
      })
      if (node) observer.current.observe(node)
    },
    [observer, countUp],
  )
  return lastElementRef
}

export const usePositionIsInterestedUpdate = (
  positionIds: number[],
  key: Key,
) => {
  return useEffect(() => {
    const set = new Set(positionIds)
    const listenerId = emitter.positionIsInterestedChanged.on(
      ({ positionId }) => {
        if (set.has(positionId)) mutate(key)
      },
    )
    return () => emitter.positionIsInterestedChanged.off(listenerId)
  }, [positionIds, key])
}

export const useStartupIsInterestedUpdate = (
  startupIds: number[],
  key: Key,
) => {
  return useEffect(() => {
    const set = new Set(startupIds)
    const listenerId = emitter.startupIsInterestedChanged.on(
      ({ startupId }) => {
        if (set.has(startupId)) mutate(key)
      },
    )
    return () => emitter.startupIsInterestedChanged.off(listenerId)
  }, [startupIds, key])
}

export const usePreferenceRequestForm = (
  request: ConditionForm | null | undefined,
  options: RequestFormOptions | undefined,
) => {
  const preference = useRef(new PreferenceStore())

  useEffect(() => {
    if (!request || !options) return
    preference.current.setData(request, options)
    preference.current.setConditions()
  }, [request, options])

  return preference.current
}

export const useIntegratedChatRoom = (
  proposal?: ScoutProposalDetail | DashboardScoutProposalDetail,
  application?: ApplicationDetail | DashboardApplicationDetail,
) => {
  const chatRoomStore = useRef(new ChatRoomStore())

  useEffect(() => {
    chatRoomStore.current.setData(proposal, application)
  }, [proposal, application])

  return chatRoomStore.current
}

export const useObserver = (
  observerCallback: (entries: IntersectionObserverEntry[]) => void,
) => {
  const observingTarget = useRef(null)
  useEffect(() => {
    if (!observingTarget.current) return
    const observer = new IntersectionObserver(observerCallback)
    observer.observe(observingTarget.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observingTarget])
  return observingTarget
}

export const usePageScroll = (
  onScrollDown: () => void,
  onScrollUp?: () => void,
  eventType: 'scroll' | 'touchmove' = 'scroll', // touchmove는 모바일에서만
  timer = 500,
  timingFunc = useDebounce,
) => {
  let prevScrollTop = 0
  const scroll = timingFunc(timer, () => {
    const nowScrollTop = document.documentElement.scrollTop
    if (nowScrollTop > prevScrollTop) onScrollDown()
    else onScrollUp?.()
    prevScrollTop = nowScrollTop
  })

  useEffect(() => {
    document.addEventListener(eventType, scroll)
    return () => document.removeEventListener(eventType, scroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const useCategorySelector = (
  data: WithIdNameParentId[],
  selectedData: WithIdNameParentId[],
  selectedParentId: number, // state값 사용
) => {
  const parentData = useMemo(
    () => data?.filter((item) => !item.parentId) || [],
    [data],
  )

  // 선택된 대분류의 포지션
  const childrenOfSelectedParent = useMemo(
    () =>
      (selectedParentId &&
        data.filter((item) => selectedParentId === item.parentId)) ||
      [],
    [data, selectedParentId],
  )

  const isParentFullySelected = useMemo(
    () =>
      childrenOfSelectedParent.filter(
        (item) => item.parentId === selectedParentId,
      ).length ===
      selectedData.filter((item) => item.parentId === selectedParentId).length,
    [childrenOfSelectedParent, selectedData, selectedParentId],
  )

  return {
    parentData,
    childrenOfSelectedParent,
    isParentFullySelected,
  }
}

export const useIsNewProfileSearch = () => {
  const router = useRouter()
  return (
    router.pathname.includes('/scouts/search') || // 검색 결과 페이지
    (router.pathname.includes('scouts/profile') &&
      router.query.ref === 'newProfileSearch') // 검색 결과 페이지에서 새 탭으로 열기를 눌러 프로필 상세 페이지로 이동한 경우
  )
}
