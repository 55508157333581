import React, { useMemo } from 'react'
import { Column, Row } from 'components/common/layout'
import {
  ApplicationFulfillment,
  DashboardScoutRequestDetail,
  DashboardScoutRequestOneQueryParams,
  ScoutRequestAiAssessment,
  ScoutRequestFilterAssessment,
} from 'types/common'
import { BaseText, MediumText, SemiBoldText } from 'components/common/text'
import { theme } from 'infra/theme'
import { LineLimitedText } from 'components/scouts/profile-detail/desc-more-view'
import { CareerDetail } from 'components/scouts/profile/career-detail'
import { UserBaseInfo } from 'components/scouts/profile/common/user-base-info'
import { Tag, TagContainer } from 'components/common/tag'
import { Attachments } from 'components/scouts/profile-detail/attachments'
import { DevEducation } from 'components/scouts/profile-detail/dev-education'
import { ManagerComment } from 'components/dashboard/scouts/profile/manager-comment'
import { getConditions, getFlattenedData } from 'utils/formatters'
import { OtherActivityDetail } from 'components/scouts/profile/other-activity-detail'
import Image from 'next/legacy/image'
import { convertNumberToHyphenPhone } from 'utils/displays'
import { useRouter } from 'next/router'
import { analytics } from 'infra/analytics'
import { MyLink } from 'components/common/link'
import { LanguageSkillDetail } from 'components/scouts/profile/language-skill-detail'
import {
  ProfileDetailSectionSubText,
  ProfileDetailConditionWrapper,
  ProfileDetailContainer,
  ProfileDetailSection,
  ProfileDetailContactText,
  ProfileDetailContentLayout,
  ProfileDetailCoverLetterBox,
  ProfileDetailCoverLetterSectionTitle,
  ProfileDetailInformText,
  ProfileDetailPlaceholderCard,
  ProfileDetailPlaceholderText,
  ProfileDetailSectionTitle,
  ProfileDetailSummaryCard,
  ProfileDetailSummaryList,
  ProfileDetailTextButton,
  ProfileDetailTextButtonWrapper,
  ProfileDetailTextWrapper,
  ProfileDetailUnpublishedBanner,
  ProfileDetailUserInfoCardWrapper,
} from 'components/scouts/profile-detail/styles'
import { ConditionTags } from 'components/scouts/search/condition-tags'
import { postRequestDashboardScoutProposalRequestResume } from 'apis/request'
import { InformationBox } from 'components/misc/information-box'
import { useBaseInfo, useStartupUser } from 'apis/hooks'
import { ProfileDetailAssessment } from 'components/dashboard/scouts/profile/profile-detail-assessment'
import { hasFitApplicationFulfillment } from 'utils/validations'
import { PositionApplicationFulfillmentCondition } from 'components/scouts/profile/common/application-fulfillment-condition'

const SectionPlaceholder: React.FC<{ placeholder: string; button: string }> = ({
  placeholder,
  button,
}) => {
  return (
    <MyLink
      href='/scouts/my-profile'
      onClick={() =>
        analytics.track('click_sp_go_to_fill_profile_from_preview')
      }
    >
      <ProfileDetailPlaceholderCard>
        <ProfileDetailPlaceholderText>
          ❗️ {placeholder}
        </ProfileDetailPlaceholderText>
        <ProfileDetailTextButton>{button}</ProfileDetailTextButton>
      </ProfileDetailPlaceholderCard>
    </MyLink>
  )
}

const ProfileFillButton: React.FCC = ({ children }) => {
  return (
    <ProfileDetailTextButtonWrapper>
      <MyLink
        href='/scouts/my-profile'
        onClick={() =>
          analytics.track('click_sp_go_to_fill_profile_from_preview')
        }
      >
        <ProfileDetailTextButton>{children}</ProfileDetailTextButton>
      </MyLink>
    </ProfileDetailTextButtonWrapper>
  )
}

export const ProfileDetail: React.FC<{
  data: DashboardScoutRequestDetail & {
    coverLetter?: string | null
    joinReason?: string | null
    fitReason?: string | null
    fulfillments?: ApplicationFulfillment
    email?: string
    phone?: string
  }
  params?: DashboardScoutRequestOneQueryParams
  curationId?: number
  isChatBlocked?: boolean
  isNarrow?: boolean
  shouldInduceToFill?: boolean // 인재 사이드에서 미리보기할 때 미완된 정보를 표시하고 작성 유도하기 위함
}> = ({
  data,
  params,
  curationId,
  isNarrow,
  isChatBlocked,
  shouldInduceToFill,
}) => {
  const router = useRouter()
  const { data: baseInfo } = useBaseInfo()
  const { data: startupUser } = useStartupUser()
  const proposalId = Number(router.query.proposalId) || null
  const isChatRooms = router.pathname.includes('chat-rooms')
  const isScoutChatRoom = isChatRooms && !!proposalId
  const isApplicant = router.pathname.includes('applicants')
  const hasAttachment = data.files.length > 0 || data.links.length > 0
  const hasAssessment =
    !!data.aiAssessment &&
    !!data.filterAssessment &&
    (!!data.assessmentScore || data.assessmentScore === 0.0)
  const hasFitFulfillment = data.fulfillments
    ? hasFitApplicationFulfillment(data.fulfillments)
    : false

  const flattenedLocations = useMemo(
    () =>
      getFlattenedData(
        baseInfo?.locations.filter((item) => !item.isRemote) || [],
        data.locations || [],
      ),
    [baseInfo?.locations, data.locations],
  )

  const requiredConditions = getConditions({
    profile: {
      ...data,
      locations: flattenedLocations,
    },
    isRequired: true,
  })
  const normalConditions = getConditions({
    profile: {
      ...data,
      locations: flattenedLocations,
    },
    isRequired: false,
  })

  return (
    <ProfileDetailContainer>
      {/* 어드민만 프로필이 보임 */}
      {!!startupUser?.isAdmin && !data.isPublished && !data.isDeleted && (
        <ProfileDetailUnpublishedBanner>
          <span>주의 ! 비공개된 프로필입니다</span>
          <span>주의 ! 비공개된 프로필입니다</span>
          <span>주의 ! 비공개된 프로필입니다</span>
        </ProfileDetailUnpublishedBanner>
      )}
      <ProfileDetailUserInfoCardWrapper>
        <UserBaseInfo data={data} params={params} isNarrow={isNarrow} />
        {data.email && data.phone && (
          <ProfileDetailSection>
            <ProfileDetailSectionTitle>연락처</ProfileDetailSectionTitle>
            <Row style={{ gap: 8 }}>
              <Image src='/phone-call.svg' width={18} height={18} alt='phone' />
              <ProfileDetailContactText href={`tel:${data.phone}`}>
                {convertNumberToHyphenPhone(data.phone)}
              </ProfileDetailContactText>
            </Row>
            <Row style={{ gap: 8 }}>
              <Image src='/mail.svg' width={18} height={18} alt='mail' />
              <ProfileDetailContactText href={`mailto:${data.email}`}>
                {data.email}
              </ProfileDetailContactText>
            </Row>
          </ProfileDetailSection>
        )}
        {!!data.summary?.length ||
        !!data.managerComment?.manager ||
        hasAssessment ||
        data.coverLetter ||
        data.joinReason ||
        data.fitReason ||
        hasFitFulfillment ? (
          <Column style={{ gap: 16 }}>
            {data.fulfillments && hasFitFulfillment && (
              <PositionApplicationFulfillmentCondition
                userName={data.name}
                fulfillments={data.fulfillments}
              />
            )}
            {hasAssessment && (
              <ProfileDetailAssessment
                assessmentScore={data.assessmentScore as number}
                aiAssessment={data.aiAssessment as ScoutRequestAiAssessment}
                filterAssessment={
                  data.filterAssessment as ScoutRequestFilterAssessment
                }
              />
            )}
            {(data.coverLetter || data.joinReason || data.fitReason) && (
              <ProfileDetailCoverLetterBox>
                <SemiBoldText>지원 메시지</SemiBoldText>
                {data.coverLetter && (
                  <LineLimitedText text={data.coverLetter} lineClamp={2} />
                )}
                {data.joinReason && (
                  <Column style={{ gap: 4 }}>
                    <ProfileDetailCoverLetterSectionTitle>
                      합류하고 싶은 이유
                    </ProfileDetailCoverLetterSectionTitle>
                    <LineLimitedText text={data.joinReason} lineClamp={2} />
                  </Column>
                )}
                {data.fitReason && (
                  <Column style={{ gap: 4 }}>
                    <ProfileDetailCoverLetterSectionTitle>
                      적합하다 생각하는 이유
                    </ProfileDetailCoverLetterSectionTitle>
                    <LineLimitedText text={data.fitReason} lineClamp={2} />
                  </Column>
                )}
              </ProfileDetailCoverLetterBox>
            )}
            {data.managerComment && data.managerComment.manager && (
              <ManagerComment
                managerComment={data.managerComment}
                isNarrow={isNarrow}
              />
            )}
            {!!data.summary?.length && (
              <ProfileDetailSummaryCard>
                {!isNarrow && (
                  <BaseText style={{ color: theme.color.gray2 }}>
                    AI 프로필 요약
                  </BaseText>
                )}
                {isNarrow ? (
                  <MediumText>
                    {data.summary.map((item) => item.title).join(' / ')}
                  </MediumText>
                ) : (
                  <ProfileDetailSummaryList>
                    {data.summary.map((item, index) => (
                      <div key={index}>
                        <li dangerouslySetInnerHTML={{ __html: item.title }} />
                        <p dangerouslySetInnerHTML={{ __html: item.content }} />
                      </div>
                    ))}
                  </ProfileDetailSummaryList>
                )}
              </ProfileDetailSummaryCard>
            )}
          </Column>
        ) : (
          ''
        )}
      </ProfileDetailUserInfoCardWrapper>
      {shouldInduceToFill &&
        !data.introduction &&
        !data.personalities.length && (
          <SectionPlaceholder
            placeholder='"자기소개" 가 없어요'
            button='작성하러 가기 (+ 최대 3점)'
          />
        )}
      {(data.introduction || data.personalities.length > 0) && (
        <ProfileDetailSection>
          <ProfileDetailSectionTitle>자기소개</ProfileDetailSectionTitle>
          {data.personalities.length > 0 && (
            <TagContainer>
              {data.personalities.map((keyword) => (
                <Tag
                  key={keyword.id}
                  dangerouslySetInnerHTML={{ __html: keyword.name }}
                />
              ))}
            </TagContainer>
          )}
          <ProfileDetailTextWrapper>
            <LineLimitedText text={data.introduction || ''} />
          </ProfileDetailTextWrapper>
          {shouldInduceToFill &&
            (!data.introduction || data.introduction.length < 20) && (
              <ProfileFillButton>
                {`"자기소개" 보충하러 가기 (+ 2점)`}
              </ProfileFillButton>
            )}
        </ProfileDetailSection>
      )}
      {shouldInduceToFill &&
        !data.expectation.description &&
        !data.expectation.keywords.length && (
          <SectionPlaceholder
            placeholder='"스타트업에 기대하는 점" 이 없어요'
            button='작성하러 가기 (+ 최대 3점)'
          />
        )}
      {(data.expectation.description ||
        data.expectation.keywords.length > 0) && (
        <ProfileDetailSection>
          <ProfileDetailSectionTitle>
            스타트업에 기대하는 점
          </ProfileDetailSectionTitle>
          {data.expectation.keywords.length > 0 && (
            <TagContainer>
              {data.expectation.keywords.map((keyword) => (
                <Tag
                  key={keyword.id}
                  dangerouslySetInnerHTML={{ __html: keyword.name }}
                />
              ))}
            </TagContainer>
          )}
          {data.expectation.description && (
            <ProfileDetailTextWrapper>
              <LineLimitedText text={data.expectation.description} />
            </ProfileDetailTextWrapper>
          )}
          {shouldInduceToFill && data.expectation.description.length < 20 && (
            <ProfileFillButton>
              {`"스타트업에 기대하는 점" 보충하러 가기 (+ 2점)`}
            </ProfileFillButton>
          )}
        </ProfileDetailSection>
      )}
      {shouldInduceToFill &&
        !data.jobSearchingReason &&
        data.workLevel.id === 3 && (
          <SectionPlaceholder
            placeholder='"이직하는 이유" 가 없어요'
            button='작성하러 가기 (+ 3점)'
          />
        )}
      {data.jobSearchingReason && data.workLevel.id === 3 && (
        <ProfileDetailSection>
          <ProfileDetailSectionTitle>이직하는 이유</ProfileDetailSectionTitle>
          <ProfileDetailTextWrapper>
            <LineLimitedText text={data.jobSearchingReason} />
          </ProfileDetailTextWrapper>
          {shouldInduceToFill && data.jobSearchingReason.length < 20 && (
            <ProfileFillButton>
              {`"이직하는 이유" 보충하러 가기 (+ 3점)`}
            </ProfileFillButton>
          )}
        </ProfileDetailSection>
      )}
      {!!data.careers?.length && (
        <ProfileDetailSection>
          <ProfileDetailSectionTitle>경력</ProfileDetailSectionTitle>
          <ProfileDetailContentLayout>
            {data.careers.map((career, index) => (
              <div key={index}>
                <CareerDetail career={career} isNarrow={isNarrow} />
                {shouldInduceToFill && !career.description && (
                  <ProfileFillButton>{`"경력 > 상세 설명" 작성하러 가기 (+ 2점)`}</ProfileFillButton>
                )}
              </div>
            ))}
          </ProfileDetailContentLayout>
        </ProfileDetailSection>
      )}
      {!!data.otherActivities?.length && (
        <ProfileDetailSection>
          <ProfileDetailSectionTitle>직무 관련 활동</ProfileDetailSectionTitle>
          <ProfileDetailContentLayout>
            {data.otherActivities.map((item, index) => (
              <OtherActivityDetail
                key={index}
                data={item}
                isNarrow={isNarrow}
              />
            ))}
          </ProfileDetailContentLayout>
        </ProfileDetailSection>
      )}
      {data.educations.length > 0 && (
        <ProfileDetailSection>
          <ProfileDetailSectionTitle>학력</ProfileDetailSectionTitle>
          <Column style={{ gap: 24 }}>
            {data.educations.map((item) => (
              <DevEducation
                key={item.id}
                education={item}
                isNarrow={isNarrow}
              />
            ))}
          </Column>
        </ProfileDetailSection>
      )}
      {shouldInduceToFill && !data.languageSkills.length && (
        <SectionPlaceholder
          placeholder='"외국어 능력" 이 없어요'
          button='작성하러 가기 (+ 1점 / 1개)'
        />
      )}
      {data.languageSkills.length > 0 && (
        <ProfileDetailSection>
          <ProfileDetailSectionTitle>외국어 능력</ProfileDetailSectionTitle>
          <ProfileDetailContentLayout>
            {data.languageSkills.map((item) => (
              <div key={item.id}>
                <LanguageSkillDetail data={item} isNarrow={isNarrow} />
                {shouldInduceToFill && !item.description && (
                  <ProfileFillButton>{`"외국어 능력 > 상세 설명" 작성하러 가기`}</ProfileFillButton>
                )}
              </div>
            ))}
          </ProfileDetailContentLayout>
        </ProfileDetailSection>
      )}
      {shouldInduceToFill && !hasAttachment && (
        <SectionPlaceholder
          placeholder='"첨부 자료" 가 없어요'
          button='업로드하러 가기 (+ 3점)'
        />
      )}
      {(isChatRooms || hasAttachment) && (
        <ProfileDetailSection>
          <Row style={{ gap: 16 }}>
            <ProfileDetailSectionTitle
              className={params?.hasAccessibleAttachment ? 'highlighted' : ''}
            >
              첨부 자료
            </ProfileDetailSectionTitle>
            {!isChatBlocked &&
              isScoutChatRoom &&
              (!hasAttachment || !data?.isResumeAllowed) && (
                <BaseText
                  style={{ color: theme.color.secondary, cursor: 'pointer' }}
                  onClick={async () => {
                    if (!proposalId) return
                    analytics.track('click_dcr_resume_request_btn', {
                      requestId: data.id,
                      proposalId,
                    })
                    await postRequestDashboardScoutProposalRequestResume(
                      proposalId,
                    )
                  }}
                >
                  {!hasAttachment ? '업로드 요청하기' : '공개 요청하기'}
                </BaseText>
              )}
          </Row>
          <Attachments
            files={data.files}
            links={data.links}
            requestId={data.id}
            curationId={curationId}
            curatedAt={data.curatedAt}
          />
          <div style={{ paddingLeft: 40 }}>
            {hasAttachment && !data.isResumeAllowed ? (
              <ProfileDetailInformText>
                {
                  '인재의 개인정보를 가린 파일만 볼 수 있어요.\n원본 파일과 링크는 스카우트 제안에 인재가 답장해올 때 공개돼요.'
                }
              </ProfileDetailInformText>
            ) : data.links.length > 0 ? (
              <InformationBox isNarrow>
                링크에는 인재의 개인 정보가 포함되어 있을 수 있으며, 이를 통해
                직접 채용할 경우 위약금이 부과될 수 있습니다.
              </InformationBox>
            ) : (
              ''
            )}
          </div>
        </ProfileDetailSection>
      )}
      {data.techStacks.length > 0 && (
        <ProfileDetailSection>
          <ProfileDetailSectionTitle>관심 스킬</ProfileDetailSectionTitle>
          <TagContainer>
            {data.techStacks.map((item) => (
              <Tag
                key={item.id}
                dangerouslySetInnerHTML={{ __html: item.name }}
              />
            ))}
          </TagContainer>
        </ProfileDetailSection>
      )}
      {!isApplicant && !isNarrow && (
        <ProfileDetailSection>
          <ProfileDetailSectionTitle>구직 조건</ProfileDetailSectionTitle>
          <Column style={{ gap: 8 }}>
            <ProfileDetailConditionWrapper>
              <ProfileDetailSectionSubText>필수</ProfileDetailSectionSubText>
              {requiredConditions.length > 0 ? (
                <ConditionTags
                  data={requiredConditions}
                  params={params}
                  salary={data.salary}
                  serviceAreas={data.serviceAreas}
                  fundingRounds={data.fundingRounds}
                />
              ) : (
                <ProfileDetailSectionSubText>
                  필수 조건이 없어요.
                </ProfileDetailSectionSubText>
              )}
            </ProfileDetailConditionWrapper>
            <ProfileDetailConditionWrapper>
              <ProfileDetailSectionSubText>선호</ProfileDetailSectionSubText>
              {normalConditions.length > 0 ? (
                <ConditionTags
                  data={normalConditions}
                  params={params}
                  salary={data.salary}
                  serviceAreas={data.serviceAreas}
                  fundingRounds={data.fundingRounds}
                />
              ) : (
                <ProfileDetailSectionSubText>
                  선호 조건이 없어요.
                </ProfileDetailSectionSubText>
              )}
            </ProfileDetailConditionWrapper>
          </Column>
        </ProfileDetailSection>
      )}
    </ProfileDetailContainer>
  )
}
