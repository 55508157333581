import React from 'react'
import { Row } from 'components/common/layout'
import { BoxInput } from 'components/common/input'
import { FiSearch } from 'react-icons/fi'
import Image from 'next/legacy/image'

export const SearchInput: React.FC<{
  value: string
  placeholder: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit?: () => void
  hideIcon?: boolean
}> = ({ value, placeholder, onChange, onSubmit, hideIcon }) => (
  <Row style={{ position: 'relative', width: '100%' }}>
    {!hideIcon && (
      <Row style={{ position: 'absolute', left: 8 }}>
        <FiSearch size={24} />
      </Row>
    )}
    <BoxInput
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyDown={(e) => {
        if (
          e.code === 'Enter' &&
          !e.nativeEvent.isComposing &&
          onSubmit !== undefined &&
          !!value.trim()
        ) {
          onSubmit()
        }
      }}
      style={{
        borderRadius: 16,
        padding: !hideIcon ? '8px 40px 8px 40px' : '8px 40px 8px 16px',
      }}
    />
    {value && onSubmit !== undefined && (
      <Row
        style={{ position: 'absolute', right: 8, cursor: 'pointer' }}
        onClick={onSubmit}
      >
        <Image src='/arrow-back.svg' alt='enter' width={24} height={24} />
      </Row>
    )}
  </Row>
)
