import {
  HOST_BASE_URL,
  IS_MAIN_PROD,
  KAKAO_REDIRECT_URI,
  KAKAO_REST_API_KEY,
} from 'infra/constants'

class Kakao {
  login(redirectUrl?: string) {
    window.location.href = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${KAKAO_REST_API_KEY}&redirect_uri=${KAKAO_REDIRECT_URI}&state=${encodeURIComponent(
      redirectUrl || window.location.href,
    )}`
  }
  logout() {
    if (!IS_MAIN_PROD) return
    window.location.href = `https://kauth.kakao.com/oauth/logout?client_id=${KAKAO_REST_API_KEY}&logout_redirect_uri=${HOST_BASE_URL}`
  }
}

export const kakao = new Kakao()
