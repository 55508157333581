import React, { Suspense, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { useStores } from 'stores/globals'
import { Card } from 'components/common/card'
import { DescText, H5Text, SmallText } from 'components/common/text'
import { Column, Row } from 'components/common/layout'
import { FiCheck } from 'react-icons/fi'
import { theme } from 'infra/theme'
import { ProposalWriteModal } from 'components/dashboard/scouts/proposal/proposal-write-modal'
import { LoadingSpinnerIcon } from 'components/common/loading-spinner'
import { useIsLoggedInStartup } from 'utils/hooks'
import { useStartupUser } from 'apis/hooks'
import { getBannerHeight } from 'components/dashboard/header-banner'

export const AutoProposalMessageFloat: React.FC = observer(() => {
  const { data: user } = useStartupUser()
  const { autoProposalMessageStore } = useStores()
  const [selectedItem, setSelectedItem] = useState<number | null>(null)
  const isLoggedInStartup = useIsLoggedInStartup()
  if (!isLoggedInStartup) return null

  const autoProposalMessageKeys = Object.keys(
    autoProposalMessageStore.autoMessages,
  )
  return (
    <>
      {autoProposalMessageKeys.length > 0 && (
        <FloatCard top={147 + getBannerHeight(user)}>
          <Row style={{ justifyContent: 'space-between' }}>
            <TitleText>자동 생성중인 AI 메시지</TitleText>
            <RemoveBtn
              onClick={() => autoProposalMessageStore.removeAllAutoMessage()}
            >
              닫기
            </RemoveBtn>
          </Row>
          <Column style={{ gap: 8 }}>
            {autoProposalMessageKeys.map((key) => {
              const id = Number(key)
              const item = autoProposalMessageStore.autoMessages[id]
              return (
                <ItemCard
                  key={id}
                  isCompleted={!item.isLoading}
                  onClick={() => setSelectedItem(id)}
                >
                  <Row style={{ gap: 16, justifyContent: 'space-between' }}>
                    <ItemText isCompleted={!item.isLoading}>
                      {item.name}님의 메시지
                    </ItemText>
                    {item.isLoading ? (
                      <LoadingSpinnerIcon />
                    ) : (
                      <FiCheck size={18} color={theme.color.white1} />
                    )}
                  </Row>
                </ItemCard>
              )
            })}
          </Column>
        </FloatCard>
      )}
      {selectedItem && (
        <Suspense>
          <ProposalWriteModal
            requestId={selectedItem}
            onClose={() => setSelectedItem(null)}
          />
        </Suspense>
      )}
    </>
  )
})

const RemoveBtn = styled(SmallText)`
  color: ${(p) => p.theme.color.gray2};
  cursor: pointer;
`

const ItemText = styled(DescText)<{ isCompleted: boolean }>`
  color: ${(p) => (p.isCompleted ? p.theme.color.white1 : p.theme.color.gray2)};
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.default};
  }
`

const FloatCard = styled(Card)<{ top: number }>`
  z-index: 1;
  gap: 16px;
  right: 25px;
  width: 280px;
  position: fixed;
  padding: 24px;
  justify-content: flex-start;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
  background-color: ${(p) => p.theme.color.white1};
  @media ${(p) => p.theme.deviceSize.desktop} {
    top: ${(p) => p.top}px;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    bottom: 80px;
    right: 16px;
    padding: 16px;
    width: 250px;
  }
`

const TitleText = styled(H5Text)`
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.desc};
  }
`

const ItemCard = styled(Card)<{ isCompleted: boolean }>`
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 8px 16px;
  }
  background-color: ${(p) =>
    p.isCompleted ? p.theme.color.secondary : p.theme.color.white1};
  padding: 12px 16px;
  cursor: pointer;
  ${(p) => p.isCompleted && `animation: highlighting 1s infinite;`}
  @keyframes highlighting {
    0% {
      background-color: ${(p) => p.theme.color.secondary};
    }
    50% {
      background-color: ${(p) => p.theme.color.secondary}aa;
    }
    100% {
      background-color: ${(p) => p.theme.color.secondary};
    }
  }
`
