import styled from 'styled-components'
import { H4Text } from 'components/common/text'
import { Row } from 'components/common/layout'
import React from 'react'
import { StartupPositionDetail } from 'types/common'
import { MyLink } from 'components/common/link'
import { getDiffFromPast } from 'utils/formatters'
import { displayPositionName } from 'utils/displays'
import { StartupLogo } from 'components/startup/startup-logo'
import { Tag, TagSize, TagType, TagContainer } from 'components/common/tag'

export const PositionHeader: React.FC<{
  data: StartupPositionDetail
}> = ({ data }) => {
  const {
    name,
    careerType,
    experienceRange,
    publishedAt,
    startup,
    averageReplyPeriod,
    isSincerityReview,
  } = data
  return (
    <Row style={{ gap: 20 }}>
      <MyLink href={`/startups/${startup.id}`}>
        <StartupLogo src={startup.thumbnail} size={90} mobileSize={44} />
      </MyLink>
      <TagContainer>
        <PositionName>
          {displayPositionName(name, careerType, experienceRange)}
        </PositionName>
        {getDiffFromPast(publishedAt) < 8 && (
          <Tag variant={TagType.RED} size={TagSize.EXTRA_SMALL}>
            NEW
          </Tag>
        )}
        {averageReplyPeriod !== null && averageReplyPeriod <= 5 && (
          <Tag variant={TagType.BLUE} size={TagSize.EXTRA_SMALL}>
            빠른 응답
          </Tag>
        )}
        {isSincerityReview && (
          <Tag variant={TagType.BLUE} size={TagSize.EXTRA_SMALL}>
            성실 검토
          </Tag>
        )}
      </TagContainer>
    </Row>
  )
}

const PositionName = styled(H4Text)`
  font-weight: ${(p) => p.theme.fontWeight.bold};
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.h3};
  }
`
