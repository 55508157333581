import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import React from 'react'
import styled from 'styled-components'
import RcSlider from 'rc-slider'
import { SliderStyler } from 'components/misc/slider-styler'
import _SlickSlider, { Settings } from 'react-slick'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { Column, Row } from 'components/common/layout'

export const Dot = styled(Row)<{ isSelected: boolean }>`
  width: ${(p) => (p.isSelected ? 20 : 12)}px;
  height: 12px;
  background-color: ${(p) =>
    p.isSelected ? p.theme.color.gray2 : p.theme.color.line};
  border-radius: 100px;
  cursor: pointer;
`

export const Slider: React.FC<{
  value: number | number[] // value가 배열로 들어오면 범위 슬라이더
  min: number
  max: number
  onChange: (v: number | number[]) => void
  step?: number
}> = ({ value, min, max, onChange, step }) => {
  return (
    <SliderStyler>
      <RcSlider
        range={typeof value === 'number' ? false : true}
        value={value}
        min={min}
        max={max}
        onChange={onChange}
        step={step}
      />
    </SliderStyler>
  )
}

const PrevArrow: React.FC = ({ ...props }) => (
  <ArrowBtn {...props}>
    <FiChevronLeft size={44} strokeWidth={1} />
  </ArrowBtn>
)

const NextArrow: React.FC = ({ ...props }) => (
  <ArrowBtn {...props}>
    <FiChevronRight size={44} strokeWidth={1} />
  </ArrowBtn>
)

export const SlickSlider: React.FCC<{ settings?: Settings }> = ({
  children,
  settings: s,
}) => {
  const settings: Settings = {
    infinite: true,
    fade: true,
    speed: 1000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    ...s,
  }
  return (
    <SliderWrapper>
      <_SlickSlider {...settings}>{children}</_SlickSlider>
    </SliderWrapper>
  )
}

const ArrowBtn = styled.div`
  position: absolute;
  &.slick-prev-btn {
    left: 0px;
  }
  &.slick-next-btn {
    right: 0px;
  }
`

const SliderWrapper = styled(Column)`
  .slick-slider {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .slick-list {
    flex: 1;
  }
  .slick-prev,
  .slick-next {
    color: unset !important;
    width: 44px !important;
    height: 44px !important;
    z-index: 1;
  }
  .slick-next {
    right: 0 !important;
  }
  .slick-prev {
    left: 0 !important;
  }
  .slick-next:before,
  .slick-prev:before {
    content: '';
  }
`
