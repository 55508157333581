import React, { useEffect, useState } from 'react'
import { Header } from 'components/misc/header'
import { Footer } from 'components/misc/footer'
import { ScoutLandingIntro } from 'components/scouts/landing/intro'
import { ScoutLandingSignboard } from 'components/scouts/landing/signboard'
import { ScoutLandingServiceDescription } from 'components/scouts/landing/service-description'
import { ScoutLandingStartupList } from 'components/scouts/landing/startup-list'
import { ScoutLandingStartupDescription } from 'components/scouts/landing/startup-description'
import { ScoutLandingEndCta } from 'components/scouts/landing/finish'
import { ScoutLandingVcBelt } from 'components/scouts/landing/vc-belt'
import { ScoutLandingFundingDescBelt } from 'components/scouts/landing/funding-belt'
import { scrollEventTrack } from 'utils/handlers'
import { analytics } from 'infra/analytics'
import { Seo } from 'components/common/seo'
import { HOST_BASE_URL } from 'infra/constants'
import {
  GuideBalloon,
  ScoutLandingCtaBtn,
} from 'components/scouts/landing/cta-button'
import styled from 'styled-components'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { COOKIE_KEY_USER } from 'infra/constants'

const Home: React.FC = () => {
  const router = useRouter()
  const isLoggedIn = !!Cookies.get(COOKIE_KEY_USER)
  const [isIntroButtonVisible, setIsIntroButtonVisible] = useState(false)
  const [isOutroButtonVisible, setIsOutroButtonVisible] = useState(false)

  useEffect(() => {
    isLoggedIn && router.push('/scouts/my-profile')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    analytics.traffic('scouts_landing')

    document.addEventListener('scroll', () =>
      scrollEventTrack('scroll_sp_landing'),
    )
    return () =>
      document.removeEventListener('scroll', () =>
        scrollEventTrack('scroll_sp_landing'),
      )
  }, [])

  return (
    <>
      <Seo url={`${HOST_BASE_URL}/`} />
      <Header />
      <ScoutLandingIntro setIsButtonVisible={setIsIntroButtonVisible} />
      <ScoutLandingVcBelt />
      <ScoutLandingSignboard />
      <ScoutLandingServiceDescription />
      <ScoutLandingStartupList />
      <ScoutLandingFundingDescBelt />
      <ScoutLandingStartupDescription />
      <ScoutLandingEndCta setIsButtonVisible={setIsOutroButtonVisible} />
      {!isIntroButtonVisible && !isOutroButtonVisible && (
        <FixedCTAWrapper>
          <GuideBalloon>
            희망 구직 조건을 등록하고, 딱 맞는 제안만 받아보세요!
          </GuideBalloon>
          <ScoutLandingCtaBtn />
        </FixedCTAWrapper>
      )}
      <Footer />
    </>
  )
}

const FixedCTAWrapper = styled.div`
  z-index: 10;
  position: fixed;
  @media ${(p) => p.theme.deviceSize.mobile} {
    bottom: 32px;
    width: 100%;
    padding: 0 16px;
  }
  @media ${(p) => p.theme.deviceSize.desktop} {
    bottom: 80px;
    left: 50%;
    transform: translate(-50%, 0);
  }
`

export default Home
