import Bugsnag from '@bugsnag/js'
import {
  postRequestStartupPositionApplicationTempApply,
  postStartupPositionApplicationTemp,
  postStartupPositionApplicationTempFulfillments,
} from 'apis/request'
import {
  MILLISECONDS_PER_MONTH,
  SKIP_POSITION_APPLICATION_CONTACT_KEY,
} from 'infra/constants'
import { makeAutoObservable } from 'mobx'
import toast from 'react-hot-toast'
import { mutate } from 'swr'
import {
  StartupDetail,
  ApplicationFulfillment,
  StartupPositionDetail,
  TempApplication,
} from 'types/common'
import { getPositionApplicationContactSkipStorage } from 'utils/storages'

export class PositionApplicationStore {
  // temp data
  joinReason: string
  fitReason: string
  fulfillments: ApplicationFulfillment
  //---
  startupDetail: StartupDetail | null
  positionDetail: StartupPositionDetail | null
  curStep: number
  curTab: number
  isOpenPreviewModal: boolean
  isOpenDoneModal: boolean
  isOpenMessageCheckModal: boolean
  hasCreatedTemp: boolean
  isSavingJoinReason: boolean
  isSavingFitReason: boolean
  isSavingFulfillment: boolean

  constructor() {
    this.joinReason = ''
    this.fitReason = ''
    this.fulfillments = {
      task: [],
      preferred: [],
      qualification: [],
    }
    this.startupDetail = null
    this.positionDetail = null
    this.curStep = 0
    this.curTab = 0
    this.isOpenPreviewModal = false
    this.isOpenDoneModal = false
    this.isOpenMessageCheckModal = false
    this.hasCreatedTemp = false
    this.isSavingJoinReason = false
    this.isSavingFitReason = false
    this.isSavingFulfillment = false
    makeAutoObservable(this)
  }

  get applicationAlert() {
    return `${this.startupDetail?.name} ‧ ${this.positionDetail?.name}에 지원하시겠어요?`
  }

  setState<K extends keyof this>(key: K, value: this[K]) {
    this[key] = value
  }

  movePrevStep() {
    this.curStep = this.curStep - 1
  }

  moveNextStep() {
    this.curStep = this.curStep + 1
  }

  async mutateTemp() {
    return mutate(
      `/startup-positions/${this.positionDetail?.id}/applications/temp`,
    )
  }

  async mutateAndUpdateTemp() {
    const { data } = await this.mutateTemp()
    this.setTemp(data)
  }

  setFulfillment(
    type: 'preferred' | 'task' | 'qualification',
    index: number,
    params: { isFit?: boolean; description?: string },
  ) {
    this.fulfillments[type][index] = {
      ...this.fulfillments[type][index],
      ...params,
    }
  }

  async apply(isPossibleApply: boolean) {
    if (!isPossibleApply) {
      Bugsnag.notify('지원 모달인데 지원 불가') // 발생하면 안 되는데 혹시나
      throw '지원 가능 횟수를 초과하여 해당 공고에 지원할 수 없어요.'
    }
    const positionId = this.positionDetail?.id || 0
    const id = toast.loading('지원중')
    await postRequestStartupPositionApplicationTempApply(positionId)
    toast.remove(id)
    const hasSkipContact = getPositionApplicationContactSkipStorage()
    if (!hasSkipContact) {
      localStorage.setItem(
        SKIP_POSITION_APPLICATION_CONTACT_KEY,
        JSON.stringify({
          expire: Date.now() + MILLISECONDS_PER_MONTH,
        }),
      )
    }
  }

  async initTemp(temp: TempApplication | null | undefined) {
    if (this.hasCreatedTemp || temp === undefined) return
    if (temp === null) {
      await this.createAndSetTemp()
    } else if (temp.fulfillments === null) {
      await this.updateFulfillmentsAndSetTemp()
    } else {
      this.setTemp(temp)
    }
    this.setState('hasCreatedTemp', true)
  }

  async createAndSetTemp() {
    // 새로운 TempApplication을 생성하고 설정
    await postStartupPositionApplicationTemp(this.positionDetail?.id || 0)
    await this.mutateAndUpdateTemp()
  }

  async updateFulfillmentsAndSetTemp() {
    // fulfillments를 업데이트하고 Temp를 설정
    await postStartupPositionApplicationTempFulfillments(
      this.positionDetail?.id || 0,
    )
    await this.mutateAndUpdateTemp()
  }

  setTemp(temp: TempApplication) {
    this.setState('fitReason', temp.fitReason || '')
    this.setState('joinReason', temp.coverLetter || temp.joinReason || '')
    temp.fulfillments && this.setState('fulfillments', temp.fulfillments)
  }
}
