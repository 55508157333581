import React from 'react'
import { MyLink } from 'components/common/link'
import {
  DashboardHeaderMenuTitle,
  HeaderMenuTitle,
  LogoutHeaderMenuTitle,
} from 'components/misc/header/styles'
import { useStores } from 'stores/globals'
import { useRouter } from 'next/router'
import { kakao } from 'infra/kakao'

export const LinkWithMenuTitle: React.FCC<{
  href: string
  newTab?: boolean
  onClick?: () => void
}> = ({ children, href, newTab, onClick }) => (
  <MyLink href={href} onClick={onClick} newTab={newTab}>
    <HeaderMenuTitle>{children}</HeaderMenuTitle>
  </MyLink>
)

export const LogoutMenuTitle: React.FC = () => {
  const router = useRouter()
  const { loginStore } = useStores()
  return (
    <LogoutHeaderMenuTitle
      onClick={async () => {
        // requireLogin이 실행되기 전에 루트로 이동해야함
        await router.push('/')
        router.reload()
        loginStore.logout()
        kakao.logout()
      }}
    >
      로그아웃
    </LogoutHeaderMenuTitle>
  )
}

export const DashboardMenuTitleWithLink: React.FCC<{
  href: string
  isSelected: boolean
  onClick?: () => void
}> = ({ href, isSelected, children, onClick }) => (
  <MyLink href={href} onClick={onClick}>
    <DashboardHeaderMenuTitle isSelected={isSelected}>
      {children}
    </DashboardHeaderMenuTitle>
  </MyLink>
)
