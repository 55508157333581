import React from 'react'
import styled from 'styled-components'
import { BaseText } from 'components/common/text'
import {
  getDiffFromPast,
  getUrlLastSegment,
  getUrlWithHttp,
} from 'utils/formatters'
import { postDashboardScoutCurationRequestViewLink } from 'apis/request'
import { IS_ANDROID } from 'infra/constants'

export const AttachedFile: React.FC<{ file: string }> = ({ file }) => {
  return (
    <a
      href={file}
      target={IS_ANDROID ? '_self' : '_blank'}
      rel='noreferrer'
      onClick={(e) => e.stopPropagation()}
    >
      <ResourceText>{getUrlLastSegment(file)}</ResourceText>
    </a>
  )
}

export const AttachedLink: React.FC<{
  link: string
  requestId?: number
  curationId?: number
  curatedAt?: string | null
}> = ({ link, requestId, curationId, curatedAt }) => {
  return (
    <a
      href={getUrlWithHttp(link)}
      target='_blank'
      rel='noreferrer'
      onClick={(e) => {
        if (curationId && requestId && curatedAt) {
          const post = getDiffFromPast(curatedAt)
          if (post >= 30) {
            postDashboardScoutCurationRequestViewLink(curationId, requestId)
          }
        }
        e.stopPropagation()
      }}
    >
      <LinkText>{link}</LinkText>
    </a>
  )
}

const ResourceText = styled(BaseText)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
`

const LinkText = styled(ResourceText)`
  color: ${(p) => p.theme.color.secondary};
`
