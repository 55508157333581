//서비스 템플릿 함수 처리

import { random } from 'lodash-es'
import {
  DashboardTempScoutProposal,
  PositionType,
  DashboardChatStatusId,
  DashboardChatStatusType,
} from 'types/common'
import { isEndWithConsonant } from 'utils/validations'

export const getChatStatusTemplate = (status: DashboardChatStatusId) => {
  switch (status) {
    case DashboardChatStatusType.CONTACTLATER:
      return '지금은 아니지만, 나중에 다시 연락해보고 싶어요'
    case DashboardChatStatusType.BREAKDOWN:
      return '채용 하지 않기로 결정했어요'
    case DashboardChatStatusType.INPROGRESS:
      return '지금 채용 전형 진행중이에요'
    case DashboardChatStatusType.SUCCEEDED:
      return '채용하기로 결정했어요'
  }
}

export const getMessageTipTemplate = (startupName: string) => {
  return {
    introPerson: {
      reason:
        '대화를 하고 있는 상대방이 어떤 일을 맡고 있는 누구인지 알면 질문하고 이야기하기 훨씬 수월해요!',
      example: `안녕하세요. 그룹바이에서 제품 팀을 이끌고 있는 COO 박상민이라고 합니다.`,
    },
    proposalReason: {
      reason: `좋은 인재는 하루에도 많은 메시지를 받기 때문에 성의 없이 복붙된 메시지는 스팸처럼 느끼고 지나쳐요. 관심 있는 이유를 프로필 정보에 근거해서 말해주면 '나에게 정말 관심이 있구나' 느끼고 좋은 인상을 남길 수 있어요!`,
      example: `김인재님의 자기소개 내용 중 “회사의 비즈니스 문제를 개발로 해결해나가는 사람”이라는 문장이 인상 깊어 메시지를 보내게 되었습니다. 업무에 있어 오너십을 중요시하시는 점도 저희 회사의 조직 문화와 잘 맞을 것 같습니다.`,
    },
    appealPoints: {
      reason: `인재는 저마다 다른 이유로 스타트업에 합류하고 싶어 해요. 프로필에 드러난 인재의 니즈를 파악해서 ${startupName}${
        isEndWithConsonant(startupName) ? '이' : '가'
      } 인재님이 찾고 있는 스타트업임을 어필해 주세요.`,
      example: `그룹바이는 김인재님이 기대하시는 “숙련된 시니어와 개발하며 성장할 수 있는 환경”을 갖추고 있습니다. 6년 차 풀스택 리드 개발자와 함께 코드 리뷰 및 페어 코딩을 진행하며, 사내 개발 스터디도 진행하고 있습니다.`,
    },
    outro: {
      reason: `빠르게 소통을 진전시키기 위해 대화를 주도해 주세요. 인재가 부담스럽지 않게 본격적인 느낌이 아닌 가벼운 스탠스로 접근하면 좋아요. 궁금한 점을 언급하고 채팅 수락을 요청해서 인재의 회신을 유도해주세요.`,
      example: `김인재님은 어떤 계기로 이 분야에서 일하게 되셨는지 궁금합니다. 이야기 더 나눠보고 싶은데 채팅 수락 부탁드립니다! 감사합니다.`,
    },
  }
}

export const getRandomIntroTemplate = (startupName: string) => {
  const INTRO_MESSAGE_TEMPLATE = [
    `안녕하세요 #{인재이름}님! 저는 ${startupName}의 (괄호까지 지우고 여기에 "직책" 입력) (괄호까지 지우고 여기에 "이름" 입력)입니다.`,
    `#{인재이름}님 안녕하세요! 저는 ${startupName}의 (괄호까지 지우고 여기에 "직책" 입력) (괄호까지 지우고 여기에 "이름" 입력)입니다.`,
    `안녕하세요 #{인재이름}님, 저는 ${startupName}의 (괄호까지 지우고 여기에 "직책" 입력)을 맡고 있는 (괄호까지 지우고 여기에 "이름" 입력)라고 합니다.`,
    `안녕하세요 #{인재이름}님 :) 저는 ${startupName}의 (괄호까지 지우고 여기에 "직책" 입력) (괄호까지 지우고 여기에 "이름" 입력)라고 합니다.`,
    `#{인재이름}님, 안녕하세요. ${startupName}의 (괄호까지 지우고 여기에 "직책" 입력) (괄호까지 지우고 여기에 "이름" 입력)라고 합니다. 이렇게 연락드리게 되어 반갑습니다!`,
    `안녕하세요! ${startupName} 에서 채용 업무를 담당하고 있는 (괄호까지 지우고 여기에 "이름" 입력)라고 합니다.`,
    `안녕하세요! ${startupName} (괄호까지 지우고 여기에 "직책" 입력) (괄호까지 지우고 여기에 "이름" 입력)입니다.`,
    `안녕하세요, ${startupName} (괄호까지 지우고 여기에 "직책" 입력) (괄호까지 지우고 여기에 "이름" 입력)입니다.`,
    `안녕하세요 ${startupName} (괄호까지 지우고 여기에 "직책" 입력) (괄호까지 지우고 여기에 "이름" 입력)입니다.`,
    `안녕하세요 ${startupName} (괄호까지 지우고 여기에 "직책" 입력) (괄호까지 지우고 여기에 "이름" 입력)이라고 합니다.`,
  ]
  return INTRO_MESSAGE_TEMPLATE[random(0, INTRO_MESSAGE_TEMPLATE.length - 1)]
}

export const getRandomOutroTemplate = (startupName: string) => {
  const OUTRO_MESSAGE_TEMPLATE = [
    `#{인재이름}님과 얘기 나눠보고 싶습니다. 채팅 수락 부탁드립니다!`,
    `채팅이나 커피챗으로 보다 자세한 이야기 나눠보고 싶습니다. 채팅 수락해주시면 감사하겠습니다 :) ${startupName}에 대해 궁금한 점이 있으시면 편하게 말씀해주세요`,
    `#{인재이름}님이 어떤 니즈를 가지고 계신지 이야기 나눠보고 싶습니다. 채팅 수락해주시면 감사드리겠습니다.`,
    `서로 핏이 어떤지 채팅이나 커피챗으로 얘기 나눠 볼 수 있을까요?`,
    `귀한 시간 내주신다면 캐주얼하게 커피챗 진행해보고 싶습니다 :)`,
    `가볍게 저희와 커피챗 어떠실까요?`,
    `부담 없이 더 많은 이야기 나눠보고 싶습니다. 저희에 대해 더 상세히 설명 드릴 수 있도록 채팅을 수락해주실 수 있을까요?`,
    `가벼운 커피챗으로 보다 구체적인 논의 이어갈 수 있으면 좋겠습니다.`,
    `#{인재이름}님과 커피챗으로 이야기 나누고 서로의 핏이 맞는지 확인하고 싶습니다. 답장 부탁드리겠습니다!`,
    `커피챗으로 뵙고 인사 나눌 수 있으면 좋겠습니다. 답장 부탁드리겠습니다 :)`,
  ]
  return OUTRO_MESSAGE_TEMPLATE[random(0, OUTRO_MESSAGE_TEMPLATE.length - 1)]
}

const getPersonalizedShouldSupervisorFulfillment = (
  parentPositionType: number | null,
  positionType: number,
) => {
  // 비즈니스, 기획
  if (parentPositionType === 19 || parentPositionType === 18)
    return '창업부터 EXIT까지의 경험이 2번 있는 사업 전략 & 기획 사수가 계시며, 함께 일하게 될 예정입니다.'
  // 마케팅
  if (parentPositionType === 15)
    return '창업부터 EXIT까지의 경험이 2번 있는 사업 기획 & 마케팅 전문가께서 사수가 계시며, 함께 일하게 될 예정입니다.'
  // 디자인
  if (parentPositionType === 16)
    return '국내 IT 대기업에서 10년간 커리어를 쌓아오신 프로덕트 디자이너께서 사수로 계시며, 함께 일하며 배울 수 있는 환경입니다.'
  // 나머지는 개발
  if (positionType === 7 || positionType === 8)
    return 'KAIST에서 자연어 처리 석사 학위를 받으시고, 핀테크 백엔드 개발 경력 20년차가 넘으시는 AI 분야 전문가께서 사수로 함께 합니다.'
  return '스타트업 극초기 멤버로 합류하여 60인 규모의 회사로 성장하는 과정에서 팀 리딩을 경험한 7년차 풀스택 개발자께서 개발 리드이자 사수로 계십니다.'
}

const getPersonalizedProposalMessage = (
  startupName: string,
  userName: string,
  interestedPositionType: PositionType,
) => {
  const { id: positionType, parentId: parentPositionType } =
    interestedPositionType
  // 비즈니스
  if (parentPositionType === 19)
    return {
      proposeReason: `${userName}님께서 가치관으로 말씀하신 것처럼, 사업을 지속 가능하게 성장시키기 위해선 현재 우리의 수준이 어디에 와있는지 인식할 수 있어야 하며, 이를 정량적으로 분석하고, 그 중 어떤 요인이 다음 성공을 불러올지 탐색할 수 있어야 합니다.\n그런 점에서, ${userName}님의 수학적으로 현상을 인식하고 사고하려는 태도가 돋보였습니다.`,
      appealPoints: `저희 ${startupName}은 수학적인 역량과 인문학적인 역량을 결합하여 일하는 팀입니다. 덕분인지 여태껏 세웠던 가설들이 높은 확률로 맞았고, 빠르게 성장하고 있습니다.`,
    }
  //  기획
  if (parentPositionType === 18)
    return {
      proposeReason: `${userName}님께서는 개발 직무에서 시작하여 서비스 기획 및 사업 기획까지 경험해보신 것으로 확인했습니다. 따라서 프로덕트와 시장에 대한 이해도가 높고, 사업 개발 쪽에서도 인사이트가 있으실 것 같아 더 자세히 대화 나눠보고 싶다는 생각이 들었습니다.\n특히 UI/UX 기획 및 개발을 함께 하실 수 있는 점, 그리고 데이터 기반의 의사 결정을 해보신 경험은 PM/서비스 기획자로서 ${userName}님만의 강점을 더욱 잘 보여주는 경험인 것 같습니다.`,
      appealPoints: `저희는 17년도 하반기에 만들어져 수많은 가설을 검증하고 도전에 실패하고 깨지며 지금의 아파트 앱 시장 1위가 되었습니다.\n하지만 '더 좋은 프로덕트, 더 좋은 가치'를 우리의 고객인 입주민 분들께 전달하기 위해 오늘보다 내일 더 성장해야 합니다.\n${userName}님께서 지금까지 쌓아오신 경험과 노하우, 인사이트들이 저희 팀의 성장에 날개를 달아줄 수 있다고 생각합니다.`,
    }
  // 마케팅
  if (parentPositionType === 15)
    return {
      proposeReason: `${userName}님께서 등록해주신 프로필 정보를 꼼꼼히 읽어보았습니다. 마케팅을 분석적이고 논리적으로 접근하시는 관점이 매우 인상 깊었습니다.`,
      appealPoints: `저희는 제너럴리스트를 찾고 있지만, 특히 브랜드와 퍼포먼스 쪽에서 역할 해주실 수 있는 분을 찾고 있습니다.\n그런 점에서 ${userName}님께서 컨텐츠 생산 측면에서는 다소 약하다고 말씀해 주셨는데, 저희는 오히려 생산된 컨텐츠를 더 전략적으로 활용해 주실 수 있는 분이 필요한지라 서로의 니즈도 잘 맞다고 생각했습니다.`,
    }
  // 디자인
  if (parentPositionType === 16)
    return {
      proposeReason: `디자이너를 찾던 중 ${userName}님과 말씀을 나눠보고 싶어 연락드립니다.\n${userName}님의 서비스 기획, 앱 플로우 기획, 앱 UX/UI 기획 및 디자인에 대한 포트폴리오를 보면서 저희가 정말로 필요로 하는 부분의 스킬을 가지신 분이라 생각했습니다.`,
      appealPoints: `저희는 17년도 하반기에 만들어져 수많은 가설을 검증하고 도전에 실패하고 깨지며 지금의 아파트 앱 시장 1위가 되었습니다.\n하지만 '더 좋은 프로덕트, 더 좋은 가치'를 우리의 고객인 입주민 분들께 전달하기 위해 오늘보다 내일 더 성장해야 합니다.\n${userName}님께서 지금까지 쌓아오신 경험과 노하우, 인사이트들이 저희 팀의 성장에 날개를 달아줄 수 있다고 생각합니다.`,
    }
  // 나머지는 개발
  // 인공지능, 데이터 엔지니어
  if (positionType === 7 || positionType === 8)
    return {
      proposeReason: `${userName}님께서 전 직장에 근무하시며 만들어오셨던 서비스와 저희가 만들고자 하는 형태가 유사하고, 특히 저희는 자체 개발한 인공지능 모델을 활용한 여러 가지 서비스를 준비 중이라 ${userName}님의 이력이 흥미로웠습니다.`,
      appealPoints: `저희 팀에는 소프트웨어, 백엔드, ML에 대한 이해가 깊으신 풀스택 개발자 두 분이 계십니다.\n- KAIST에서 자연어처리 석사 학위를 받으시고 핀테크 백엔드 개발 경력 20년차\n- 카카오페이, 신한카드 등에서 데이터 백엔드 엔지니어로 일하시다 합류하신 분으로 뛰어난 소프트웨어, 백엔드 개발자\n\n${userName}님께서 연구하고자 하시는 방향은 저희 팀에서 현재 연구 중인 분야와 매우 유사합니다. 목표로 하시는 커리어와 경험은 모두 저희 팀에서 하실 수 있다고 확실히 약속드릴 수 있습니다.`,
    }
  // ios, 안드로이드, 크로스플랫폼 앱
  const appDev = [3, 4, 5]
  if (appDev.includes(positionType))
    return {
      proposeReason: `${userName}님께서 등록해주신 프로필 정보를 모두 읽었고, “비즈니스 임팩트를 만들어가는 Problem Solver”라는 정체성이 매우 인상 깊었습니다.\n${userName}님께서 현재 저희 팀에 딱 필요한 ${interestedPositionType.name} 개발자라고 느껴 제안드리게 되었습니다.`,
      appealPoints: `저희 서비스는 특히 시장에서 사용하기 쉽고 유려한 UX/UI로 경쟁력을 갖춘다는 전략을 갖고 있어 ${userName}님께서 비즈니스적으로 기여할 수 있는 부분이 많습니다.\n저희 개발팀은 IT 대기업 출신 ${interestedPositionType.name} 전문가들로 구성되어 있으며, 짧은 기간 다른 조직과 비교할 수 없는 밀도있는 성장과 커리어 점프를 약속드릴 수 있습니다.`,
    }
  // 프론트엔드, 게임 클리이언트, VR/AR/MR/XR
  const frontDev = [1, 9, 12]
  if (frontDev.includes(positionType))
    return {
      proposeReason: `${userName}님께서 등록해주신 프로필 정보를 모두 읽었고, “비즈니스 임팩트를 만들어가는 Problem Solver”라는 정체성이 매우 인상 깊었습니다.\n${userName}님께서 현재 저희 팀에 딱 필요한 ${interestedPositionType.name} 개발자라고 느껴 연락드리게 되었습니다.`,
      appealPoints: `저희 서비스는 특히 시장에서 사용하기 쉽고 유려한 UX/UI로 경쟁력을 갖춘다는 전략을 취하고 있어, ${userName}님께서 비즈니스적으로 기여할 수 있는 부분이 많습니다.\n저희 개발팀은 IT 대기업 출신 ${interestedPositionType.name} 전문가들로 구성되어 있으며, 짧은 기간 다른 조직과 비교할 수 없는 밀도 높은 성장과 커리어 점프를 약속드릴 수 있습니다.`,
    }
  // 백엔드, 풀스택, 게임 서버, 소프트웨어 엔지니어, QA 엔지니어, 하드웨어 엔지니어
  return {
    proposeReason: `${userName}님께서 전 직장에 근무하시며 만들어오셨던 서비스와 저희가 만들고자 하는 형태가 유사하여, ${userName}님의 이력이 흥미로웠습니다.`,
    appealPoints: `저희 팀에는 소프트웨어, 백엔드, ML에 대한 이해가 깊으신 풀스택 개발자 두 분이 계십니다.\n- KAIST에서 자연어처리 석사 학위를 받으시고 핀테크 백엔드 개발 경력 20년차\n- 카카오페이, 신한카드 등에서 데이터 백엔드 엔지니어로 일하시다 합류하신 분으로 뛰어난 소프트웨어, 백엔드 개발자\n\n${userName}님께서 목표로 하시는 '대 AI 시대에 필요한 개발자'가 되기 위해 갖춰야할 필수적인 역량을 저희 팀에서 아주 밀도 있게 배우실 수 있을거라 약속드립니다.`,
  }
}

export const getProposalExampleTemplate = (
  startupName: string,
  userName: string,
  interestedPositionType: PositionType,
): DashboardTempScoutProposal => {
  const { proposeReason, appealPoints } = getPersonalizedProposalMessage(
    startupName,
    userName,
    interestedPositionType,
  )
  return {
    fulfillments: [
      {
        dataKey: 'memberCount',
        description:
          '현재 10명의 팀원으로 구성되어 있으며, 4개월 내로 5명을 추가 채용할 계획입니다.',
      },
      {
        dataKey: 'locations',
        description:
          '출근하실 사무실은 강남 테헤란로에 위치하여, 교통이 편리합니다.',
      },
      {
        dataKey: 'fundingRounds',
        description:
          '최근 30억 규모의 Series A 투자 유치를 완료했습니다. 평균 1년 주기로 후속 투자유치를 완료하며 빠르게 성장하고 있는 스타트업입니다.',
      },
      {
        dataKey: 'salary',
        description:
          '제시하신 연봉은 충분히 지급 가능하며, 추후 미팅을 통해 상세히 이야기 나누고 싶습니다.',
      },
      {
        dataKey: 'shouldSupervisor',
        description: getPersonalizedShouldSupervisorFulfillment(
          interestedPositionType.parentId,
          interestedPositionType.id,
        ),
      },
      {
        dataKey: 'shouldStockOption',
        description:
          '초기에 합류해주시는 팀원들께는 그에 맞는 보상으로써 높은 수준의 스톡옵션을 지급해 드립니다.',
      },
    ],
    introPerson: `안녕하세요, ${startupName} 공동 창업자이자 채용을 담당하고 있는 Steve입니다.`,
    proposeReason,
    appealPoints,
    outro: `그룹바이 채팅 또는 커피챗으로 저희 회사와 저라는 사람을 더 자세히 소개해 드리고, ${userName}님과 대화 나눠보고 싶습니다.\n답장 주시기를 기다리겠습니다. 감사합니다!`,
    positions: [],
    isResumeRequest: true,
  }
}
