import React from 'react'
import { observer } from 'mobx-react'
import { PositionApplicationStore } from 'stores/position-application'
import { PositionApplicationFitStepSectionText } from 'containers/positions/application/styles'
import { Column } from 'components/common/layout'
import { PositionApplicationFulfillmentList } from 'containers/positions/application/position-application-fulfillment-list'

export const PositionApplicationFulfillment: React.FC<{
  store: PositionApplicationStore
}> = observer(({ store }) => {
  return (
    <Column style={{ gap: 40, padding: 10 }}>
      {store.fulfillments.task.length > 0 && (
        <Column style={{ gap: 16 }}>
          <PositionApplicationFitStepSectionText>
            경험해봤거나 자신있는 업무를 모두 선택해주세요
          </PositionApplicationFitStepSectionText>
          <PositionApplicationFulfillmentList type='task' store={store} />
        </Column>
      )}
      {store.fulfillments.qualification.length > 0 && (
        <Column style={{ gap: 16 }}>
          <PositionApplicationFitStepSectionText>
            해당하는 자격요건을 모두 선택해주세요
          </PositionApplicationFitStepSectionText>
          <PositionApplicationFulfillmentList
            type='qualification'
            store={store}
          />
        </Column>
      )}
      {store.fulfillments.preferred.length > 0 && (
        <Column style={{ gap: 16 }}>
          <PositionApplicationFitStepSectionText>
            해당하는 우대사항을 모두 선택해주세요
          </PositionApplicationFitStepSectionText>
          <PositionApplicationFulfillmentList type='preferred' store={store} />
        </Column>
      )}
    </Column>
  )
})
