//데이터 가공 함수 처리

import moment from 'moment'
import {
  Condition,
  DashboardScoutProposal,
  IdpCertificateStatus,
  Range,
  ScoutFulfillment,
  ScoutProposal,
  ScoutRequestDataKeyIsRequired,
  ScoutRequestMiscOptions,
  TechStackWithPositionTypes,
  WithIdName,
  DashboardChatStatusId,
  ApplicationItem,
  DashboardApplicationItem,
  DashboardChatStatusType,
  ScoutRequestFormDataKey,
  TrackingHireAlertLevel,
  AdminMatchingListChatStatus,
  CareerHistory,
  OtherActivityHistory,
  EducationHistory,
  WithIdNameParentId,
  WithIdNameNullableParentId,
  DashboardScoutRequestOneQueryParams,
  ChatItem,
  DashboardApplicationSummary,
  ApplicationFulfillment,
  ApplicationFulfillmentCondition,
  LanguageSkill,
  NumberOrNull,
} from 'types/common'
import { theme } from 'infra/theme'
import {
  addHighlight,
  calcPublishedDate,
  displaySalaryNegotiableWithStockOption,
  getDevCountCondition,
  getMemberCountCondition,
  getSalaryCondition,
} from 'utils/displays'
import { TagType } from 'components/common/tag'
import {
  APPLICANTS_TAB_ID,
  VALID_NAME_VARIABLE,
  WITH_HTTP_ADDRESS,
} from 'infra/constants'

const today = new Date()

export const getChatStatusTagType = (
  status: DashboardChatStatusType | AdminMatchingListChatStatus,
) => {
  switch (status) {
    case DashboardChatStatusType.INPROGRESS:
      return TagType.GREEN
    case DashboardChatStatusType.CONTACTLATER:
      return TagType.YELLOW
    case DashboardChatStatusType.BREAKDOWN:
      return TagType.RED
    case DashboardChatStatusType.SUCCEEDED:
      return TagType.BLUE
    default: // AdminMatchingListChatStatus.WAITING 일 때만 해당함
      return TagType.GREEN
  }
}

export const getChatStatusDescription = (
  value: DashboardChatStatusId,
  isProposalAccepted: boolean | undefined, // 채팅 수락 여부 (과거에 accept 메시지 없이 채팅 수락된 경우, acceptedAt 없기 때문에 필요) / 스카우트가 아니라서 값 없으면 undefined
  proposalAcceptedAt?: string | null, // 채팅 수락
  resumeAcceptedAt?: string | null,
  isRejected?: boolean,
) => {
  if (
    value !== DashboardChatStatusType.BREAKDOWN &&
    value !== DashboardChatStatusType.INPROGRESS
  )
    return ''
  if (value === DashboardChatStatusType.BREAKDOWN && isRejected)
    return '(채팅종료)'
  if (
    value === DashboardChatStatusType.INPROGRESS &&
    isProposalAccepted === false
  )
    return '(수락 전)'
  if (value === DashboardChatStatusType.INPROGRESS && proposalAcceptedAt)
    return `(${calcPublishedDate(proposalAcceptedAt)} 수락)`
  if (value === DashboardChatStatusType.INPROGRESS && resumeAcceptedAt)
    return `(${calcPublishedDate(resumeAcceptedAt)} 서류합격)`
  return ''
}

export const displayTrackingHireAlertLevel = (
  alertLevel: TrackingHireAlertLevel | null,
) => {
  if (!alertLevel) return ''
  if (alertLevel === 1) return 'Level 1'
  if (alertLevel === 2) return 'Level 2'
  if (alertLevel === 3) return '운영팀 직접 연락'
  return ''
}

export const formatRelativePastDate = (date: string | null) =>
  date ? (getDiffDays(date) > 0 ? `${getDiffDays(date)}일 전` : '오늘') : '-'

// 24시간 단위가 아니라, 두 날짜의 자정을 기준으로 일수를 계산함 (예. 1/1 6:00와 1/3 2:00 사이의 diff days는 2)
export const getDiffDays = (past: string) => {
  const pastDate = new Date(past)
  pastDate.setHours(0, 0, 0, 0)
  const nowDate = new Date()
  nowDate.setHours(0, 0, 0, 0)
  const diff = moment(nowDate).diff(pastDate, 'days')
  return diff
}

// 시간 단위(time stamp)로 계산해서 일자를 계산
export const getDiffFromPast = (past: string) => {
  const pastDate = new Date(past)
  const diff = Math.floor(
    (today.getTime() - pastDate.getTime()) / (1000 * 3600 * 24),
  )
  return diff
}

export const getConditionSatisfactionPercentage = (
  conditions: Condition[],
  fulfillments: ScoutFulfillment[],
) => {
  return Math.floor(
    (conditions.filter((condition) =>
      fulfillments.map((item) => item.dataKey).includes(condition.dataKey),
    ).length /
      conditions.length) *
      100,
  )
}

export const getPercentage = (n: number) => Math.round(n * 100)

export const getFitTechStacks = (
  positionIds: number[],
  techStacks: TechStackWithPositionTypes[],
) => {
  if (positionIds.length === 0) return techStacks
  const positionTypesSet = new Set(positionIds)
  return [...techStacks].sort((a, b) => {
    const isAFit =
      a.positionTypes && a.positionTypes.some((x) => positionTypesSet.has(x))
    const isBFit =
      b.positionTypes && b.positionTypes.some((x) => positionTypesSet.has(x))
    if (isAFit && !isBFit) return -1
    else if (!isAFit && isBFit) return 1
    return 0
  })
}

export const getAddedDays = (days: number) => {
  const result = new Date()
  result.setDate(result.getDate() + days)
  return result
}

export const getIdpCertificateStatusType = (status?: IdpCertificateStatus) => {
  const idpCertificateStatusTypes = {
    pending: {
      description: '인증 대기중',
      color: '#006C4D',
      tooltip: '인증이 완료되면 카카오톡으로 알려드려요!',
    },
    failed: {
      description: '인증 실패',
      color: theme.color.alert,
      tooltip: '배지 인증이 반려되었어요 :(',
    },
    notFilled: {
      description: '정보 미작성',
      color: '#FF8B64',
      tooltip: '경험을 증명할 소개 정보가 없는 상태에요 :(',
    },
  }
  if (!status) return undefined
  if (status.isCertified === null) return idpCertificateStatusTypes.pending
  if (!status.isCertified) return idpCertificateStatusTypes.failed
  if (!status.isContentFilled) return idpCertificateStatusTypes.notFilled
  return null
}

interface GetConditionParams {
  profile: {
    salary: Range | null
    devCount: Range | null
    memberCount: Range | null
    locations: string[] | null
    serviceAreas: WithIdName[] | null
    fundingRounds: WithIdName[] | null
    miscOptions: ScoutRequestMiscOptions | null
    requiredConditions: ScoutRequestDataKeyIsRequired[] | null
  }
  isRequired: boolean
}

export const getConditions = ({ profile, isRequired }: GetConditionParams) => {
  const result: { dataKey: ScoutRequestFormDataKey; value: string }[] = []
  profile.requiredConditions?.forEach((cond) => {
    if (cond.isRequired === isRequired) {
      switch (cond.dataKey) {
        case 'devCount':
          if (profile.devCount?.min === 0 && profile.devCount.max === 10) return
          result.push({
            dataKey: cond.dataKey,
            value: getDevCountCondition(
              profile.devCount?.min || 0,
              profile.devCount?.max || 0,
            ),
          })
          break
        case 'fundingRounds':
          if (!profile.fundingRounds?.length) return
          result.push({
            dataKey: cond.dataKey,
            value: profile.fundingRounds.map((item) => item.name).join(' ‧ '),
          })
          break
        case 'locations':
          if (!profile.locations?.length) return
          result.push({
            dataKey: cond.dataKey,
            value: profile.locations.join(' ‧ '),
          })
          break
        case 'memberCount':
          if (profile.memberCount?.min === 0 && profile.memberCount.max === 20)
            return
          result.push({
            dataKey: cond.dataKey,
            value: getMemberCountCondition(
              profile.memberCount?.min || 0,
              profile.memberCount?.max || 0,
            ),
          })
          break
        case 'salary':
          if (profile.salary?.min === 0 && profile.salary.max === 10000) return
          result.push({
            dataKey: cond.dataKey,
            value: getSalaryCondition(
              profile.salary?.min || 0,
              profile.salary?.max || 0,
            ),
          })
          break
        case 'serviceAreas':
          if (!profile.serviceAreas?.length) return
          result.push({
            dataKey: cond.dataKey,
            value: profile.serviceAreas.map((item) => item.name).join(' ‧ '),
          })
          break
        case 'shouldCto':
          if (!profile.miscOptions?.shouldCto) return
          result.push({ dataKey: cond.dataKey, value: 'CTO 있음' })
          break
        case 'shouldSupervisor':
          if (!profile.miscOptions?.shouldSupervisor) return
          result.push({ dataKey: cond.dataKey, value: '사수 있음' })
          break
        case 'remoteWorkPreference':
          if (!profile.miscOptions?.remoteWorkPreference) return
          result.push({
            dataKey: cond.dataKey,
            value:
              profile.miscOptions.remoteWorkPreference ===
              'FULL_REMOTE_NECESSARY'
                ? '원격 근무만 가능'
                : '부분 원격 근무 필요',
          })
          break
        case 'shouldStockOption':
          if (!profile.miscOptions?.shouldStockOption) return
          result.push({
            dataKey: cond.dataKey,
            value: displaySalaryNegotiableWithStockOption(
              profile.miscOptions.isSalaryNegotiableWithStockOption,
            ),
          })
          break
        default:
          break
      }
    }
  })
  return result
}

export const getProposalChatList = (
  proposals: Partial<DashboardScoutProposal & ScoutProposal>[],
) => {
  return proposals.map((item) => {
    return {
      name: item.name || '',
      startupId: item.startupId,
      requestId: item.requestId,
      proposalId: item.proposalId || null,
      applicationId: null,
      lastMessage: item.lastMessage || null,
      unreadMessagesCount: item.unreadMessagesCount || 0,
      image: item.thumbnail || item.profileImage || '',
      isRejected: item.isRejected,
      isCanceled: item.isCanceled,
      hasConfirmedMeet: item.hasConfirmedMeet,
      hasDoneMeet: item.hasDoneMeet,
      status: item.status,
      chatStep: item.chatStep,
      acceptedAt: item.acceptedAt,
      shouldBrokingScoutProposal: item.shouldBrokingScoutProposal,
    }
  })
}

export const getApplicationChatList = (
  applications: Partial<DashboardApplicationItem & ApplicationItem>[],
) => {
  return applications.map((item) => {
    return {
      name: item.user?.name || item.startup?.name || '',
      requestId: item.scoutRequestId || item.scoutRequest?.id,
      proposalId: null,
      startupId: item.startup?.id,
      applicationId: item.id || null,
      lastMessage: item.lastMessage || null,
      unreadMessagesCount: item.unreadMessagesCount || 0,
      image: item.user?.image || item.startup?.thumbnail || '',
      statusId: item.statusId,
      chatStep: item.chatStep,
      resumeAcceptedAt: item.resumeAcceptedAt,
      scheduledRejectionDate: item.scheduledRejectionDate,
      rejectedAt: item.rejectedAt,
    }
  })
}

export function getUrlLastSegment(url: string) {
  return url.split('/').pop() || ''
}

export const getUrlWithHttp = (url: string) =>
  !WITH_HTTP_ADDRESS.test(url) ? `http://${url}` : url

interface HighlightProfile {
  positions: WithIdName[]
  techStacks: WithIdName[]
  careers: CareerHistory[]
  otherActivities: OtherActivityHistory[]
  summary: { title: string; content: string }[] | null
  educations: EducationHistory[]
  languageSkills?: LanguageSkill[]
}

export const highlightProfile = (
  profile: HighlightProfile,
  params: DashboardScoutRequestOneQueryParams,
): HighlightProfile => {
  const positions = profile.positions.map((position) => {
    params.keywords.forEach((keyword) => {
      position.name = addHighlight(position.name || '', keyword)
    })
    return {
      ...position,
      name: params.positions.includes(position.id)
        ? addHighlight(position.name)
        : position.name,
    }
  })
  const techStacks = profile.techStacks.map((techStack) => {
    params.keywords.forEach((keyword) => {
      techStack.name = addHighlight(techStack.name || '', keyword)
    })
    return {
      ...techStack,
      name: params.techStacks.includes(techStack.id)
        ? addHighlight(techStack.name)
        : techStack.name,
    }
  })
  const careers = profile.careers.map((career) => {
    params.keywords.forEach((keyword) => {
      career.position = addHighlight(career.position || '', keyword)
      career.description = addHighlight(career.description || '', keyword)
      career.company.name = addHighlight(career.company.name || '', keyword)
    })
    const techStacks = career.techStacks.map((techStack) => {
      params.keywords.forEach((keyword) => {
        techStack.name = addHighlight(techStack.name || '', keyword)
      })
      return {
        ...techStack,
        name: params.techStacks.includes(techStack.id)
          ? addHighlight(techStack.name)
          : techStack.name,
      }
    })
    return {
      ...career,
      techStacks,
    }
  })
  const otherActivities = profile.otherActivities.map((otherActivity) => {
    params.keywords.forEach((keyword) => {
      otherActivity.title = addHighlight(otherActivity.title || '', keyword)
      otherActivity.description = addHighlight(
        otherActivity.description || '',
        keyword,
      )
    })
    const techStacks = otherActivity.techStacks.map((techStack) => {
      params.keywords.forEach((keyword) => {
        techStack.name = addHighlight(techStack.name || '', keyword)
      })
      return {
        ...techStack,
        name: params.techStacks.includes(techStack.id)
          ? addHighlight(techStack.name)
          : techStack.name,
      }
    })
    return {
      ...otherActivity,
      techStacks,
    }
  })
  const summary = profile.summary
    ? profile.summary.map((_item) => {
        let title = _item.title
        params.keywords.forEach(
          (keyword) => (title = addHighlight(title, keyword)),
        )
        let content = _item.content
        params.keywords.forEach(
          (keyword) => (content = addHighlight(content, keyword)),
        )
        return { title, content }
      })
    : null
  const educations = profile.educations.map((education) => {
    params.keywords.forEach((keyword) => {
      education.major = addHighlight(education.major || '', keyword)
      education.school.name = addHighlight(education.school.name || '', keyword)
      if (education.category) {
        education.category.name = addHighlight(
          education.category.name || '',
          keyword,
        )
      }
    })
    return {
      ...education,
      major:
        params.isEducationCs && education.isCs
          ? addHighlight(education.major)
          : education.major,
      school: {
        ...education.school,
        name:
          params.schools.includes(education.school.id) ||
          !!education.school.groups?.find((id) =>
            params.schoolGroups.includes(id),
          )
            ? addHighlight(education.school.name)
            : education.school.name,
      },
      category: education.category && {
        ...education.category,
        name: params.educationCategories.includes(education.category.id)
          ? addHighlight(education.category.name)
          : education.category.name,
      },
    }
  })
  const languageSkillsParams = profile.languageSkills
    ? (JSON.parse(params.languageSkills) as {
        language: number
        level: NumberOrNull
      }[])
    : []
  const languageSkills = profile.languageSkills
    ? profile.languageSkills.map((item) => {
        const languageSkillsParamIds = languageSkillsParams.map(
          (languageSkill) => languageSkill.language,
        )
        const languageSkillsParamLevels = languageSkillsParams.map(
          (languageSkill) => languageSkill.level,
        )
        params.keywords.forEach((keyword) => {
          item.level.name = addHighlight(item.level.name, keyword)
          item.language.name = addHighlight(item.language.name, keyword)
          item.description = addHighlight(item.description, keyword)
        })
        return {
          ...item,
          level: {
            ...item.level,
            name: languageSkillsParamLevels.includes(item.level.id)
              ? addHighlight(item.level.name)
              : item.level.name,
          },
          language: {
            ...item.language,
            name: languageSkillsParamIds.includes(item.language.id)
              ? addHighlight(item.language.name)
              : item.language.name,
          },
        }
      })
    : []

  return {
    positions,
    techStacks,
    careers,
    otherActivities,
    summary,
    educations,
    languageSkills,
  }
}

// 상위 분류가 있을 때 하위 필터 선택 시 하위 필터의 출처를 보여주기 위함
export const getSelectedChildrenByParent = (
  data: WithIdNameParentId[],
  selectedData: WithIdNameNullableParentId[],
) => {
  const parentData = data.filter((item) => !item.parentId)
  return parentData.map((parent) => {
    return {
      id: parent.id,
      name: parent.name,
      parentId: parent.parentId,
      total: data.filter((item) => item.parentId === parent.id).length || 0,
      selectedChildren:
        selectedData.filter((child) => child.parentId === parent.id) || [],
      hasParentInSelectedData: !!selectedData.find(
        (item) => item.id === parent.id,
      ), // TODO(son): 모든 유저가 지역 설정을 했을때 제거
    }
  })
}

export const getFlattenedData = (
  data: WithIdNameParentId[],
  selectedData: WithIdNameParentId[],
) => {
  const selectedChildrenOfParent = getSelectedChildrenByParent(
    data,
    selectedData,
  )

  const flattenedData = selectedChildrenOfParent
    .map((parent) => {
      if (
        parent.hasParentInSelectedData ||
        parent.total === parent.selectedChildren.length
      )
        return `${parent.name} 전체`
      return parent.selectedChildren.map(
        (child) => `${parent.name} ${child.name}`,
      )
    })
    .flat()
    .filter((item) => item && item.length > 0)

  return flattenedData
}

export const matchNameVariable = (message: string) => {
  let hasWrongVariable = false
  let prevValue
  const matches = [...message.matchAll(VALID_NAME_VARIABLE)]
  for (const matchVariable of matches) {
    if (matchVariable[1] !== '인재이름' && prevValue !== matchVariable[1]) {
      message = message.replaceAll(
        matchVariable[0],
        `<span class="error">${matchVariable[0]}</span>`,
      )
      hasWrongVariable = true
    }
    prevValue = matchVariable[1]
  }
  return { hasWrongVariable, message }
}

// text에 특수 문자가 포함될 수 있으므로, 정규식에서 안전하게 사용하기 위해 이스케이프 처리를 합니다.
export const escapeRegExp = (text: string) => {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export const highlightNameInText = (text: string, name: string) => {
  const escapedName = escapeRegExp(name)
  const regex = new RegExp(`(${escapedName})\\s?(님|씨)`, 'g')
  return {
    text: text.replaceAll(regex, '<span>$1</span>$2'),
    isHighlightedName: regex.test(text),
  }
}

export const sortChatList = (chatList: ChatItem[]) => {
  return chatList.sort((a, b) => {
    // 현재 아이템에 lastMessage 가 없으면 현재 아이템을 뒤로
    if (!a.lastMessage) return 1
    // 다음 아이템에 lastMessage 가 없으면 현재 아이템을 앞으로
    if (!b.lastMessage) return -1
    // 둘 다 lastMessage 가 있는 상태
    // sentAt 값이 큰 걸 앞으로, 똑같으면 유지
    if (a.lastMessage.sentAt > b.lastMessage.sentAt) return -1
    if (a.lastMessage.sentAt < b.lastMessage.sentAt) return 1
    return 0
  })
}

export const sumApplicantCount = (summary: DashboardApplicationSummary[]) =>
  (
    summary.map(
      (item) =>
        item.status.find((status) => status.id === APPLICANTS_TAB_ID)?.count ||
        0,
    ) || []
  ).reduce((a, b) => a + b, 0)

export const calculatePositionFulfillmentScore = (
  fulfillment: ApplicationFulfillment,
): number => {
  const TASK_TOTAL = 10
  const QUALIFICATION_TOTAL = 60
  const PREFERRED_TOTAL = 30

  // 어떤 항목이 빈 배열인지 판별
  const taskEmpty = fulfillment.task.length === 0
  const qualificationEmpty = fulfillment.qualification.length === 0
  const preferredEmpty = fulfillment.preferred.length === 0

  // 유효한(비어있지 않은) 항목들에 대한 total 합
  let activeTotals = 0
  if (!taskEmpty) activeTotals += TASK_TOTAL
  if (!qualificationEmpty) activeTotals += QUALIFICATION_TOTAL
  if (!preferredEmpty) activeTotals += PREFERRED_TOTAL

  // activeTotals가 0이라면 모든 항목이 비어있으므로 점수는 0
  if (activeTotals === 0) {
    return 0
  }

  // 100으로 만들기 위해서 scale 계산
  const scale = 100 / activeTotals

  // 실제 사용될 총합 (빈 배열 제거 후 스케일링)
  const scaledTaskTotal = taskEmpty ? 0 : TASK_TOTAL * scale
  const scaledQualificationTotal = qualificationEmpty
    ? 0
    : QUALIFICATION_TOTAL * scale
  const scaledPreferredTotal = preferredEmpty ? 0 : PREFERRED_TOTAL * scale

  const taskScore = calcCategoryScore(fulfillment.task, scaledTaskTotal)
  const qualificationScore = calcCategoryScore(
    fulfillment.qualification,
    scaledQualificationTotal,
  )
  const preferredScore = calcCategoryScore(
    fulfillment.preferred,
    scaledPreferredTotal,
  )

  const totalScore =
    Math.round(taskScore) +
    Math.round(qualificationScore) +
    Math.round(preferredScore)

  // 올림 처리
  return totalScore
}

const calcCategoryScore = (
  items: ApplicationFulfillmentCondition[],
  categoryTotal: number,
): number => {
  if (items.length === 0) return 0

  const itemValue = categoryTotal / items.length
  let categoryScore = 0

  for (const item of items) {
    if (!item.isFit) {
      // 체크 안된 항목: 0점
      continue
    }

    // 체크된 항목 점수 계산
    let itemScore = itemValue * 0.8 // 기본 체크 시 0.8배
    if (item.description && item.description.trim().length >= 50) {
      // 설명 50자 이상 작성 시 추가 0.2배
      itemScore = itemValue * 1.0 // 0.8 + 0.2 = 1.0배
    }

    categoryScore += itemScore
  }

  return categoryScore
}
