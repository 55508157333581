import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import {
  PositionApplicationCharCountText,
  PositionApplicationNoticeText,
  PositionApplicationPrevBtn,
  PositionApplicationStepSubText,
  PositionApplicationStepTitleText,
  PositionApplicationNextBtn,
  PositionApplicationTempSavingTag,
  PositionApplicationFormContainer,
  PositionApplicationTextArea,
  PositionApplicationTextAreaContainer,
} from 'containers/positions/application/styles'
import { Column, Row } from 'components/common/layout'
import { PositionApplicationStore } from 'stores/position-application'
import { useDebounce, useIsDesktopCSR } from 'utils/hooks'
import { putStartupPositionApplicationTemp } from 'apis/request'
import { detectAndNoticePrivateInfoFromApplication } from 'utils/handlers'

export const PositionApplicationJoinReasonStep: React.FC<{
  store: PositionApplicationStore
}> = observer(({ store }) => {
  const isDesktop = useIsDesktopCSR()
  const saveTempJoinReason = useDebounce(1000, async (v: string) => {
    await putStartupPositionApplicationTemp(
      store.positionDetail?.id || 0,
      false,
      { joinReason: v },
    )
    store.setState('isSavingJoinReason', false)
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => store.setState('curTab', 1), [])

  const clickNext = async () => {
    // 1. "건너뛰기" 일 때
    if (!store.joinReason) return store.moveNextStep()
    // 2. "다음" 일 때
    try {
      if (store.isSavingJoinReason) return
      store.setState('isSavingJoinReason', true)
      const hasDetected = await detectAndNoticePrivateInfoFromApplication(
        store.positionDetail?.id || 0,
        { joinReason: store.joinReason },
      )
      // 2-1. 탐지된 게 없으면 다음으로 넘어감
      if (!hasDetected) {
        store.setState('isSavingJoinReason', false)
        return store.moveNextStep()
      }
      // 2-2. 탐지된 게 있으면 mutate한 데이터로 업데이트
      store.mutateAndUpdateTemp()
    } catch (e) {
      alert(e)
    }
    store.setState('isSavingJoinReason', false)
  }

  return (
    <PositionApplicationFormContainer>
      <Column style={{ gap: 16, height: '100%' }}>
        <Column style={{ gap: 4 }}>
          {!isDesktop ? (
            <PositionApplicationStepSubText>
              <span>{store.startupDetail?.name}</span>에{' '}
              <span>합류하고 싶은 이유</span>
            </PositionApplicationStepSubText>
          ) : (
            <PositionApplicationStepTitleText>
              합류하고 싶은 이유를 작성해주세요
            </PositionApplicationStepTitleText>
          )}
        </Column>
        <PositionApplicationTextAreaContainer>
          <PositionApplicationTextArea
            value={store.joinReason || ''}
            placeholder={`‧ 채용 공고의 어떤 부분이 매력적으로 느껴졌는지 알려주세요\n‧ ${store.startupDetail?.name}에 관심을 가지게 된 이유를 작성해도 좋아요`}
            onChange={(e) => {
              if (e.target.value.length > 200) return
              store.setState('isSavingJoinReason', true)
              store.setState('joinReason', e.target.value)
              saveTempJoinReason(e.target.value)
            }}
          />
          <Row style={{ height: 30 }}>
            {store.isSavingJoinReason && (
              <PositionApplicationTempSavingTag>
                임시 저장 중
              </PositionApplicationTempSavingTag>
            )}
            <PositionApplicationCharCountText>
              {store.joinReason?.length || 0}/200
            </PositionApplicationCharCountText>
          </Row>
        </PositionApplicationTextAreaContainer>
      </Column>
      <Column style={{ gap: 8, textAlign: 'center' }}>
        <PositionApplicationNoticeText>
          성의 있는 메시지로 서류 합격 가능성을 최대 <span>6배</span>{' '}
          높여보세요!
        </PositionApplicationNoticeText>
        <Row style={{ gap: 8 }}>
          <PositionApplicationPrevBtn onClick={() => store.movePrevStep()}>
            이전
          </PositionApplicationPrevBtn>
          <PositionApplicationNextBtn onClick={clickNext}>
            {store.joinReason ? '다음' : '건너뛰기'}
          </PositionApplicationNextBtn>
        </Row>
      </Column>
    </PositionApplicationFormContainer>
  )
})
