import React from 'react'
import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import { Row } from 'components/common/layout'
import { ResponsiveDisplay } from 'components/common/layout'

export const HeaderLogo: React.FC = () => {
  const router = useRouter()
  return (
    <>
      <ResponsiveDisplay isDisableMobile as={Row}>
        <Image
          onClick={() => router.push('/')}
          alt='logo'
          src='/logo2.svg'
          width={114}
          height={29}
          style={{ cursor: 'pointer' }}
          priority
        />
      </ResponsiveDisplay>
      <ResponsiveDisplay isDisableDesktop as={Row}>
        <Image
          onClick={() =>
            router.pathname.includes('dashboard')
              ? router.push('/dashboard/scouts/search/start')
              : router.push('/')
          }
          alt='logo'
          src='/logo.svg'
          width={38}
          height={38}
          style={{ cursor: 'pointer' }}
          priority
        />
      </ResponsiveDisplay>
    </>
  )
}
