import qs from 'query-string'
import useSWR, { SWRConfiguration, useSWRConfig } from 'swr'
import { Envelope } from 'types/common'
import { getRequest } from 'apis/fetcher'
import { ApiError } from 'apis/error'
import { IS_BROWSER } from 'infra/constants'
import Bugsnag from '@bugsnag/js'
import { captureException } from '@sentry/nextjs'

class SwrResponse<T> {
  ok: boolean
  _success?: Envelope<T>
  _failure?: ApiError

  constructor(ok: boolean) {
    this.ok = ok
  }

  get data() {
    return this._success?.data
  }

  get error() {
    return this._failure
  }

  get status() {
    return this._success?.status || this._failure?.status
  }
}

const globalAbortController = new AbortController()
const LOGIN_ERROR_MESSAGE = '로그인이 필요해요.'
const TYPE_ERROR_MESSAGE = '서버와 연결이 불안정합니다.'
const NOT_FOUND_MESSAGE = '요청한 데이터를 찾을 수 없습니다.'
const DEFAULT_ERROR_MESSAGE =
  '오류가 발생했습니다.\n새로고침하거나 홈으로 이동하여 다시 시도해주세요.\n\n서비스 이용에 불편을 드려서 죄송합니다.'
let isUnloaded = false // 새로고침 시 중단된 요청에 대한 에러 예외처리

const wrappedGetRequest = async <T>(
  path: string,
  paramAbortController?: AbortController,
) => {
  const abortController = paramAbortController || globalAbortController
  IS_BROWSER &&
    window.addEventListener('beforeunload', () => {
      isUnloaded = true
    })
  try {
    const res = await getRequest(path, { signal: abortController.signal })
    const swrRes = new SwrResponse<T>(true)
    swrRes._success = res as Envelope<T>
    return swrRes
  } catch (e: unknown) {
    const error = e as ApiError
    if (error.message === LOGIN_ERROR_MESSAGE) {
      // 불안정한 로그아웃 시 모든 요청 중단
      abortController.abort()
    }
    if (!abortController.signal.aborted && !isUnloaded) {
      // 의도한 요청 중단 시 에러수집 안함
      Bugsnag.notify(error)
      captureException(error)
      // 로그인 에러 메시지 제외하고 알럿 제공
      if (IS_BROWSER && error.message !== LOGIN_ERROR_MESSAGE) {
        alert(
          error.status === 404 && !error.message
            ? NOT_FOUND_MESSAGE
            : error.name === 'TypeError'
            ? TYPE_ERROR_MESSAGE
            : error.message || DEFAULT_ERROR_MESSAGE,
        )
      }
    }
    const swrRes = new SwrResponse<T>(false)
    swrRes._failure = e as ApiError
    return swrRes
  } finally {
    IS_BROWSER &&
      window.removeEventListener('beforeunload', () => {
        isUnloaded = false
      })
  }
}

interface UseEnvelopeSWROptions {
  abortController?: AbortController
}

export const useEnvelopeSWR = <T>(
  path: string | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: Record<string, any>,
  config?: SWRConfiguration,
  options?: UseEnvelopeSWROptions,
) => {
  // should be cached with query params as a whole
  const wholePath =
    path === null ? null : `${path}${params ? `?${qs.stringify(params)}` : ''}`
  const { abortController } = options || {}
  const { data } = useSWR<SwrResponse<T>, ApiError>(
    wholePath,
    () => wrappedGetRequest<T>(wholePath || '', abortController),
    config,
  )
  return data || new SwrResponse<T>(false)
}

export const useRemoveSWRErrorCache = (
  basePath: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: Record<string, any>,
) => {
  const { cache } = useSWRConfig()
  const cacheKey = `${basePath}${params ? `?${qs.stringify(params)}` : ''}`
  const cachedData = cache.get(cacheKey)

  if (cachedData && cachedData.error) {
    cache.delete(cacheKey)
  }
}
