import React, { useEffect, useState } from 'react'
import { Modal } from 'components/common/modal'
import { Column } from 'components/common/layout'
import { BaseText, BoldText, SemiBoldText } from 'components/common/text'
import { Tag, TagSize, TagType } from 'components/common/tag'
import { useIsDesktopCSR } from 'utils/hooks'
import { BtnSize, BtnType, Button } from 'components/common/button'
import { CheckBox, CheckBoxSize } from 'components/common/check-box'
import { getContractNoticeModalStorage } from 'utils/storages'
import {
  CONTRACT_NOTICE_MODAL_KEY,
  MILLISECONDS_PER_7DAY,
  MILLISECONDS_PER_DAY,
} from 'infra/constants'
import { useStartupUser } from 'apis/hooks'

export const ContractNoticeModal: React.FC = () => {
  const isDesktop = useIsDesktopCSR()
  const { data: user } = useStartupUser()
  const [isOpen, setIsOpen] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  useEffect(() => {
    const payload = getContractNoticeModalStorage()
    if (!payload) return setIsOpen(true)
    if (payload.expire < Date.now()) setIsOpen(true)
  }, [])
  if (!isOpen || !user) return null
  return (
    <Modal
      width={700}
      disableBackgroundClick
      onClose={() => {
        localStorage.setItem(
          CONTRACT_NOTICE_MODAL_KEY,
          JSON.stringify({
            expire:
              Date.now() +
              (isChecked ? MILLISECONDS_PER_7DAY : MILLISECONDS_PER_DAY),
          }),
        )
        setIsOpen(false)
      }}
    >
      <Column style={{ position: 'relative' }}>
        <Column
          style={{ padding: isDesktop ? '48px 32px 16px' : '48px 20px 16px' }}
        >
          <Column style={{ gap: 40 }}>
            <Column style={{ gap: 24 }}>
              <Column style={{ gap: 12 }}>
                <BoldText style={{ fontSize: isDesktop ? 20 : 18 }}>
                  🚨 2025년 그룹바이 계약서 내용이 일부 변경되었어요.
                </BoldText>
                <ul style={{ paddingLeft: 24 }}>
                  <BaseText as='li' style={{ fontSize: 16, lineHeight: '1.5' }}>
                    채용수수료는 더욱 저렴하게 바뀌었어요.
                  </BaseText>
                  <BaseText as='li' style={{ fontSize: 16, lineHeight: '1.5' }}>
                    명확한 기준이 없었던 계약직 환불 규정이 계약기간에 따라
                    구체적으로 바뀌었어요.
                  </BaseText>
                </ul>
              </Column>
              <Column style={{ gap: 12 }}>
                <BoldText style={{ fontSize: 16 }}>주요 변경사항</BoldText>
                <Column style={{ gap: 4 }}>
                  <Tag
                    size={isDesktop ? TagSize.MEDIUM : TagSize.SMALL}
                    variant={TagType.BLUE}
                  >
                    채용수수료 적용 연봉 구간 변경
                  </Tag>
                  <Column>
                    <BaseText style={{ lineHeight: '1.5' }}>
                      수수료가 저렴해지는 연봉구간이 늘어났어요.
                    </BaseText>
                    <BaseText style={{ lineHeight: '1.5' }}>
                      변경 후 : 연봉 2800만원 미만 4% (기존 : 2400만원 미만)
                    </BaseText>
                    <BaseText style={{ lineHeight: '1.5' }}>
                      변경 후 : 연봉 4000만원 미만 5% (기존 : 3960만원 미만)
                    </BaseText>
                  </Column>
                </Column>
                <Column style={{ gap: 4 }}>
                  <Tag
                    size={isDesktop ? TagSize.MEDIUM : TagSize.SMALL}
                    variant={TagType.BLUE}
                  >
                    프리랜서 수수료율 변경
                  </Tag>
                  <BaseText style={{ lineHeight: '1.5' }}>
                    프리랜서 계약시 계약금의 6%로 채용수수료가 책정되어요. (기존
                    : 10%)
                  </BaseText>
                </Column>
                <Column style={{ gap: 4 }}>
                  <Tag
                    size={isDesktop ? TagSize.MEDIUM : TagSize.SMALL}
                    variant={TagType.BLUE}
                  >
                    계약직 채용시 채용수수료 환불규정 변경
                  </Tag>
                  <Column>
                    <BaseText style={{ lineHeight: '1.5' }}>
                      6개월 초과 계약직의 경우 환불규정
                    </BaseText>
                    <ul style={{ paddingLeft: 24 }}>
                      <BaseText as='li' style={{ lineHeight: '1.5' }}>
                        3개월 이내 퇴사시 75% 환불, 1개월 이내 퇴사시 100% 환불
                      </BaseText>
                    </ul>
                    <BaseText style={{ lineHeight: '1.5' }}>
                      6개월 이내 계약직의 경우 환불규정
                    </BaseText>
                    <ul style={{ paddingLeft: 24 }}>
                      <BaseText as='li' style={{ lineHeight: '1.5' }}>
                        계약기간 50% 이내 퇴사시 75% 환불, 계약기간 25% 이내
                        퇴사시 100% 환불
                      </BaseText>
                    </ul>
                  </Column>
                </Column>
                <Column style={{ gap: 4 }}>
                  <Tag
                    size={isDesktop ? TagSize.MEDIUM : TagSize.SMALL}
                    variant={TagType.BLUE}
                  >
                    변경사항 발생시 한달 전 전달 후 자동연장
                  </Tag>
                  <BaseText style={{ lineHeight: '1.5' }}>
                    계약서에 수정사항이 발생할때 마다 매년 새롭게 계약서를
                    서명하는 것이 번거로우시죠? {isDesktop && <br />}
                    앞으로 계약을 갱신을 할 경우 그룹바이가 기업에게 1개월 전
                    변경사항을 전달하고 {isDesktop && <br />}
                    기업이 1개월 이내 해제의사를 표현하지 않을 경우 자동 1년
                    연장됩니다.
                  </BaseText>
                </Column>
              </Column>
            </Column>
            <Column style={{ gap: 4, textAlign: 'center' }}>
              <BaseText style={{ fontSize: 14, color: '#DC362E' }}>
                ⚠ 서명가능 기간 : 2025.01.13~2025.02.12
              </BaseText>
              <SemiBoldText style={{ fontSize: 14 }}>
                변경된 계약서에 서명하지 않으면 서비스 이용에 제한이 생길 수
                있습니다.
              </SemiBoldText>
            </Column>
          </Column>
        </Column>
        <Column
          style={{
            gap: 12,
            bottom: 0,
            alignItems: 'end',
            position: 'sticky',
            padding: isDesktop ? '12px 24px 20px' : '12px 20px 20px',
            backgroundColor: '#FFFFFF',
          }}
        >
          <CheckBox
            size={CheckBoxSize.SMALL}
            checked={isChecked}
            onClick={() => setIsChecked((prev) => !prev)}
          >
            일주일간 보지 않기
          </CheckBox>
          <Button
            size={BtnSize.MEDIUM}
            variant={BtnType.SECONDARY}
            onClick={() => {
              localStorage.setItem(
                CONTRACT_NOTICE_MODAL_KEY,
                JSON.stringify({
                  expire:
                    Date.now() +
                    (isChecked ? MILLISECONDS_PER_7DAY : MILLISECONDS_PER_DAY),
                }),
              )
              setIsOpen(false)
              window.open(
                'https://app.modusign.co.kr/link/26c37520-d14f-11ef-bdb3-678e48126c21/authentication?shareType=URL',
                '_blank',
              )
            }}
          >
            변경된 계약서 서명하기
          </Button>
        </Column>
      </Column>
    </Modal>
  )
}
