import React, { ChangeEvent } from 'react'
import styled from 'styled-components'
import { BaseText } from 'components/common/text'
import Image from 'next/legacy/image'
import { WithIdLink } from 'types/common'
import { getUrlLastSegment } from 'utils/formatters'
import { Row } from 'components/common/layout'
import { useIsDesktop } from 'utils/hooks'
import { textEllipsis } from 'components/common/styles'
import { RadioIcon } from 'components/common/icon'

export const FileInputBox: React.FC<{
  onChangeEvent: (e: ChangeEvent<HTMLInputElement>) => void
  isRecommendingPDF?: boolean
  isRequirePDF?: boolean
}> = ({ onChangeEvent, isRecommendingPDF, isRequirePDF }) => {
  const isDesktop = useIsDesktop()
  return (
    <>
      <UploadLabel htmlFor='uploadInput'>
        <InputFileBox>
          <Image alt='plus file' src='/file-plus.svg' width={24} height={24} />
          <PlaceHolder>
            {isDesktop
              ? `파일을 올려주세요${
                  isRecommendingPDF
                    ? ` (PDF 파일을 권장해요)`
                    : isRequirePDF
                    ? ' (PDF 파일만 가능해요)'
                    : ''
                }`
              : `파일 업로드${
                  isRecommendingPDF
                    ? ' (PDF 파일 권장)'
                    : isRequirePDF
                    ? ' (PDF 파일만 가능)'
                    : ''
                }`}
          </PlaceHolder>
        </InputFileBox>
      </UploadLabel>
      <UploadInput
        id='uploadInput'
        type='file'
        accept={isRequirePDF ? '.pdf' : ''}
        onChange={onChangeEvent}
      />
    </>
  )
}

export const FileSelectedBox: React.FC<{
  file: WithIdLink | File
  onRemove: (file: WithIdLink | File) => void
  isSelected?: boolean
  onSelect?: (file: WithIdLink | File) => void
}> = ({ file, onRemove, isSelected, onSelect }) => {
  return (
    <SelectedFileBox>
      <BtnTextWrapper
        onClick={() => {
          if (isSelected !== undefined) {
            onSelect?.(file)
          } else {
            window.open(
              file instanceof File ? URL.createObjectURL(file) : file.link,
              '_black',
            )
          }
        }}
      >
        {isSelected !== undefined && <RadioIcon isSelected={isSelected} />}
        <DownloadLinkText>
          {file instanceof File ? file.name : getUrlLastSegment(file.link)}
        </DownloadLinkText>
      </BtnTextWrapper>
      <IconButton>
        <Image
          alt='delete file'
          src='/x-circle-gray.svg'
          width={16}
          height={16}
          layout='fixed'
          onClick={() => onRemove(file)}
        />
      </IconButton>
    </SelectedFileBox>
  )
}

const BtnTextWrapper = styled(Row)`
  width: 100%;
  gap: 12px;
  cursor: pointer;
  ${textEllipsis}
`

const DownloadLinkText = styled(BaseText)`
  color: ${(p) => p.theme.color.gray1};
  ${textEllipsis}
`

const SelectedFileBox = styled(Row)`
  width: 100%;
  gap: 12px;
  padding: 14px 12px;
  border-radius: 4px;
  justify-content: space-between;
  border: 1px solid ${(p) => p.theme.color.primary};
  background-color: ${(p) => p.theme.color.white1};
  ${textEllipsis}
`
const InputFileBox = styled(Row)`
  gap: 8px;
  padding: 12px;
  background-color: #efefef;
  border-radius: 4px;
`

const UploadLabel = styled.label`
  width: 100%;
  cursor: pointer;
`

const PlaceHolder = styled(BaseText)`
  color: ${(p) => p.theme.color.gray2};
`

const UploadInput = styled.input`
  position: fixed;
  width: 0;
  height: 0;
  opacity: 0;
`

const IconButton = styled(Row)`
  cursor: pointer;
  margin-block: auto;
  align-items: center;
`
