import React, { useEffect } from 'react'
import { useStores } from 'stores/globals'
import { observer } from 'mobx-react'

export const LoginVerifier: React.FCC = observer((props) => {
  const { loginStore } = useStores()
  useEffect(() => {
    loginStore.verify()
    loginStore.verifyStartup()
    loginStore.blockMultiLogin()
  }, [loginStore])
  return <>{props.children}</>
})
