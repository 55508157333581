import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Modal } from 'components/common/modal'
import { observer } from 'mobx-react'
import { PositionApplicationStore } from 'stores/position-application'
import { theme } from 'infra/theme'
import { BtnSize, BtnType, Button } from 'components/common/button'
import { Column, Row } from 'components/common/layout'
import {
  BaseText,
  BoldText,
  MediumText,
  SemiBoldText,
} from 'components/common/text'
import { InformationBox } from 'components/misc/information-box'
import { getAddedDays } from 'utils/formatters'
import { CheckCircleIcon } from 'components/common/icon'
import { useIsDesktopCSR } from 'utils/hooks'
import { useRouter } from 'next/router'
import { useScoutMyRequest, useUser } from 'apis/hooks'
import {
  DESIGN_POSITION_ID,
  MARKETING_POSITION_ID,
  PLAN_MANAGER_POSITION_ID,
} from 'infra/constants'

export const PositionApplicationDoneModal: React.FC<{
  store: PositionApplicationStore
  onClose: () => void
}> = observer(({ store, onClose }) => {
  if (!store.positionDetail) return null
  return (
    <Modal width={600} onClose={onClose} disableCloseBtn>
      <ApplyDone store={store} onClose={onClose} />
    </Modal>
  )
})

const ApplyDone: React.FC<{
  store: PositionApplicationStore
  onClose: () => void
}> = observer(({ store, onClose }) => {
  const { data: user } = useUser()
  const { data: myProfile } = useScoutMyRequest()
  const router = useRouter()
  const isDesktop = useIsDesktopCSR()
  const isVisibleTipNotice =
    !!user?.interestedPositionTypes.find(
      (item) =>
        item.parentId === MARKETING_POSITION_ID ||
        item.parentId === DESIGN_POSITION_ID ||
        item.parentId === PLAN_MANAGER_POSITION_ID,
    ) &&
    !myProfile?.links.length &&
    !myProfile?.files.length

  return (
    <ContentBox>
      <Column style={{ gap: 24 }}>
        <Column style={{ gap: 8, alignItems: 'center' }}>
          <CheckCircleIcon size={isDesktop ? 32 : 24} />
          <PageTitle>지원을 완료했어요!</PageTitle>
        </Column>
        <ExplainText>
          채용 담당자의 검토 결과는{isDesktop ? ' ' : <br />}
          <span style={{ fontWeight: theme.fontWeight.bold }}>
            {moment(getAddedDays(14)).format('M월 D일')}
          </span>
          까지 알림톡으로 안내드려요.
          <br />
          좋은 결과 있으시길 바랍니다.
        </ExplainText>
      </Column>
      <ApplyInfo>
        <ApplyInfoCol style={{ textAlign: 'end' }}>
          <PositionText>기업명</PositionText>
          <PositionText>포지션 · 경력</PositionText>
        </ApplyInfoCol>
        <ApplyInfoCol>
          <SemiBoldText>{store.startupDetail?.name}</SemiBoldText>
          <SemiBoldText>
            {store.positionDetail?.name} · {store.positionDetail?.careerType}
          </SemiBoldText>
        </ApplyInfoCol>
      </ApplyInfo>
      {isVisibleTipNotice ? (
        <Column style={{ alignItems: 'center', gap: 8 }}>
          <TipTitleText>
            <span>Tip.</span> 서류합격률을 높여보시겠어요?
          </TipTitleText>
          <ExplainText>
            포트폴리오 없이 지원하면{' '}
            <span style={{ color: theme.color.alert }}>합격 가능성</span>이
            낮아요!
          </ExplainText>
        </Column>
      ) : (
        <InformationBox>
          지원 메시지를 수정하려면 지원 취소 후 다시 지원해주세요. 이력서 열람
          전까지 지원을 취소할 수 있어요.
        </InformationBox>
      )}
      <Column style={{ gap: 8 }}>
        <Button
          size={isDesktop ? undefined : BtnSize.MEDIUM}
          onClick={() => {
            router.replace(
              isVisibleTipNotice
                ? '/scouts//my-profile?resolvedInfoFill=resume'
                : '/positions/my-activities',
            )
            onClose()
          }}
        >
          {isVisibleTipNotice
            ? '👉 프로필에 포트폴리오 첨부하기'
            : '지원 정보 보기'}
        </Button>
        <Button
          onClick={() => {
            const curationId = Number(router.query.curationId)
            const isCuration = !!curationId
            if (isCuration) {
              if (router.pathname !== '/my-curation')
                router.replace(`/my-curation?curationId=${curationId}`)
            } else {
              if (router.pathname !== '/positions') router.replace('/positions')
            }
            onClose()
          }}
          variant={BtnType.OUTLINE}
          size={isDesktop ? undefined : BtnSize.MEDIUM}
        >
          다른 채용 공고 둘러보기
        </Button>
      </Column>
    </ContentBox>
  )
})

const PositionText = styled(MediumText)`
  color: ${(p) => p.theme.color.gray2};
`

const ContentBox = styled(Column)`
  gap: 40px;
  padding: 32px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 32px;
    padding: 32px 20px;
  }
`

const PageTitle = styled(SemiBoldText)`
  font-size: ${(p) => p.theme.fontSize.h3};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h4};
  }
`

const ExplainText = styled(BaseText)`
  line-height: 1.6;
  text-align: center;
  color: ${(p) => p.theme.color.black4};
  font-size: ${(p) => p.theme.fontSize.desc};
  span {
    color: ${(p) => p.theme.color.secondary};
    font-size: ${(p) => p.theme.fontSize.h5};
    font-weight: ${(p) => p.theme.fontWeight.bold};
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: 14px;
    span {
      font-size: ${(p) => p.theme.fontSize.desc};
    }
  }
`

const ApplyInfo = styled(Row)`
  gap: 32px;
  padding: 20px;
  justify-content: center;
  border-top: 1px solid ${(props) => props.theme.color.gray3};
  border-bottom: 1px solid ${(props) => props.theme.color.gray3};
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 20px 0;
  }
`

const ApplyInfoCol = styled(Column)`
  gap: 8px;
`

const TipTitleText = styled(BoldText)`
  font-size: ${(p) => p.theme.fontSize.h4};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h5};
  }
  span {
    color: ${(p) => p.theme.color.primary};
  }
`
