import React from 'react'
import styled from 'styled-components'
import { Row } from 'components/common/layout'
import { BaseText } from 'components/common/text'
import { theme } from 'infra/theme'

export enum TagSize {
  EXTRA_SMALL = 'extra-small',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum TagType {
  OUTLINE = `outline`,
  HIGHLIGHT = 'highlight',
  RED = 'red',
  ORANGE = 'orange',
  YELLOW = 'yellow',
  GREEN = 'green',
  BLUE = 'blue',
  NAVY = 'navy',
  PURPLE = 'purple',
  GRAY = 'gray',
  HEAVY_RED = 'heavy-red',
  HEAVY_ORANGE = 'heavy-orange',
  HEAVY_YELLOW = 'heavy-yellow',
  HEAVY_GREEN = 'heavy-green',
  HEAVY_BLUE = 'heavy-blue',
  HEAVY_NAVY = 'heavy-navy',
  HEAVY_PURPLE = 'heavy-purple',
  HEAVY_GRAY = 'heavy-gray',
  LIGHT_GRAY = 'light-gray',
}

export const TagTypeMapping = {
  [TagType.OUTLINE]: {
    color: '#262626',
    backgroundColor: '#FFFFFF',
  },
  [TagType.HIGHLIGHT]: {
    color: '#262626',
    backgroundColor: '#FDD00066',
  },
  [TagType.RED]: {
    color: '#F4686D',
    backgroundColor: '#FFEAEE',
  },
  [TagType.ORANGE]: {
    color: '#F36E52',
    backgroundColor: '#FFECE8',
  },
  [TagType.YELLOW]: {
    color: '#E28C0A',
    backgroundColor: '#FCF5EB',
  },
  [TagType.GREEN]: {
    color: '#009B2D',
    backgroundColor: '#E4F7E9',
  },
  [TagType.BLUE]: {
    color: '#3B82F6',
    backgroundColor: '#E7F1FF',
  },
  [TagType.NAVY]: {
    color: '#5365B6',
    backgroundColor: '#E3E6F3',
  },
  [TagType.PURPLE]: {
    color: '#7D22FB',
    backgroundColor: '#F0E6FD',
  },
  [TagType.GRAY]: {
    color: '#262626',
    backgroundColor: '#F1F2F6',
  },
  [TagType.HEAVY_RED]: {
    color: '#FFFFFF',
    backgroundColor: '#F4686D',
  },
  [TagType.HEAVY_ORANGE]: {
    color: '#FFFFFF',
    backgroundColor: '#F36E52',
  },
  [TagType.HEAVY_YELLOW]: {
    color: '#FFFFFF',
    backgroundColor: '#E28C0A',
  },
  [TagType.HEAVY_GREEN]: {
    color: '#FFFFFF',
    backgroundColor: '#009B2D',
  },
  [TagType.HEAVY_BLUE]: {
    color: '#FFFFFF',
    backgroundColor: '#3B82F6',
  },
  [TagType.HEAVY_NAVY]: {
    color: '#FFFFFF',
    backgroundColor: '#5365B6',
  },
  [TagType.HEAVY_PURPLE]: {
    color: '#FFFFFF',
    backgroundColor: '#7D22FB',
  },
  [TagType.HEAVY_GRAY]: {
    color: '#FFFFFF',
    backgroundColor: '#000000',
  },
  [TagType.LIGHT_GRAY]: {
    color: '#888888',
    backgroundColor: '#F1F2F6',
  },
}

const TagSizeMapping = {
  [TagSize.EXTRA_SMALL]: {
    padding: '2px 4px',
    fontSize: '12px',
    lineHeight: '16px',
  },
  [TagSize.SMALL]: {
    padding: '4px 8px',
    fontSize: '11px',
    lineHeight: '16px',
  },
  [TagSize.MEDIUM]: {
    padding: '4px 8px',
    fontSize: theme.fontSize.small,
    lineHeight: '19px',
  },
  [TagSize.LARGE]: {
    padding: '8px 16px',
    fontSize: theme.fontSize.default,
    lineHeight: '24px',
  },
}

export const Tag = styled.div<{
  variant?: TagType
  size?: TagSize
}>`
  width: fit-content;
  border-radius: 2px;
  font-weight: ${(p) => p.theme.fontWeight.medium};
  ${(p) => TagSizeMapping[p.size || TagSize.MEDIUM]};
  ${(p) => TagTypeMapping[p.variant || TagType.OUTLINE]};
  border: 1px solid
    ${(p) =>
      !p.variant || p.variant === TagType.OUTLINE
        ? theme.color.line
        : TagTypeMapping[p.variant || TagType.OUTLINE].backgroundColor};
`

export const Tags: React.FC<{
  data: string[]
  maxLength?: number
}> = ({ data, maxLength }) => {
  return (
    <>
      {data?.map((item, idx) => {
        if (maxLength && idx >= maxLength) return
        return (
          <Tag key={idx}>
            <BaseText>{item}</BaseText>
          </Tag>
        )
      })}
    </>
  )
}

export const TagContainer = styled(Row)`
  flex-wrap: wrap;
  gap: 8px;
`
