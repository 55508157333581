import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { Modal } from 'components/common/modal'
import { PositionApplicationStore } from 'stores/position-application'
import { theme } from 'infra/theme'
import { Column } from 'components/common/layout'
import {
  PositionApplicationPrevBtn,
  PositionApplicationStepSubText,
  PositionApplicationSubmitBtn,
} from 'containers/positions/application/styles'
import { CheckBox, CheckBoxSize } from 'components/common/check-box'
import { ProfilePreview } from 'components/scouts/profile/profile-preview'
import {
  MILLISECONDS_PER_MONTH,
  SKIP_POSITION_APPLICATION_PREVIEW_KEY,
} from 'infra/constants'
import { useIsDesktop } from 'utils/hooks'
import { useStores } from 'stores/globals'
import { usePositionDetail } from 'apis/hooks'
import {
  ApplicationFulfillment,
  ApplicationFulfillmentCondition,
} from 'types/common'

const clearDescriptionIfNotFit = (
  items: ApplicationFulfillmentCondition[],
): ApplicationFulfillmentCondition[] => {
  return items.map((item) => ({
    ...item,
    description: item.isFit ? item.description : '',
  }))
}

const getPreviewApplicationFulfillment = (
  fulfillment: ApplicationFulfillment,
): ApplicationFulfillment => {
  return {
    task: clearDescriptionIfNotFit(fulfillment.task),
    preferred: clearDescriptionIfNotFit(fulfillment.preferred),
    qualification: clearDescriptionIfNotFit(fulfillment.qualification),
  }
}

export const PositionApplicationPreviewModal: React.FC<{
  store: PositionApplicationStore
  onClose: () => void
}> = observer(({ store, onClose }) => {
  const { positionListStore } = useStores()
  const isDesktop = useIsDesktop()
  const [isChecked, setIsChecked] = useState(false)
  const { data: position } = usePositionDetail(store.positionDetail?.id)
  // useMemo로 fulfillments 계산 최적화
  const previewFulfillments = useMemo(() => {
    return getPreviewApplicationFulfillment(store.fulfillments)
  }, [store.fulfillments])

  return (
    <Modal width={700} onClose={onClose}>
      <Header>
        <Column style={{ gap: 8 }}>
          <PositionApplicationStepSubText
            style={{
              fontSize: isDesktop ? theme.fontSize.h4 : theme.fontSize.desc,
            }}
          >
            <span>{store.startupDetail?.name}</span>에게 이렇게 보여요
          </PositionApplicationStepSubText>
          <PositionApplicationStepSubText
            style={{
              fontSize: isDesktop
                ? theme.fontSize.default
                : theme.fontSize.small,
              lineHeight: isDesktop ? '24px' : '20px',
            }}
          >
            프로필은 지원 후에도 수정 가능하며
            {isDesktop ? ' ' : <br />}
            지원서에 즉시 반영돼요
            <br />(<span>마이페이지 {'>'} 내 프로필</span>
            에서 수정)
          </PositionApplicationStepSubText>
        </Column>
        <CheckBox
          size={CheckBoxSize.SMALL}
          checked={isChecked}
          onClick={() => setIsChecked((prev) => !prev)}
        >
          당분간 보지 않기
        </CheckBox>
      </Header>
      <ProfilePreview
        joinReason={store.joinReason || ''}
        fitReason={store.fitReason || ''}
        fulfillments={previewFulfillments}
      />
      <BtnContainer>
        <PositionApplicationPrevBtn
          onClick={() => store.setState('isOpenPreviewModal', false)}
        >
          이전
        </PositionApplicationPrevBtn>
        <PositionApplicationSubmitBtn
          onClick={async () => {
            try {
              if (!confirm(store.applicationAlert)) return
              if (isChecked)
                localStorage.setItem(
                  SKIP_POSITION_APPLICATION_PREVIEW_KEY,
                  JSON.stringify({
                    expire: Date.now() + MILLISECONDS_PER_MONTH,
                  }),
                )
              await store.apply(!!position?.userApplication.isPossibleApply)
              positionListStore.mutatePositionList()
              store.setState('isOpenPreviewModal', false)
              store.setState('isOpenDoneModal', true)
            } catch (e) {
              alert(e)
            }
          }}
        >
          지원하기
        </PositionApplicationSubmitBtn>
      </BtnContainer>
    </Modal>
  )
})

const Header = styled(Column)`
  gap: 16px;
  padding: 40px 0;
  text-align: center;
  align-items: center;
  background-color: ${(p) => p.theme.color.background3};
`

const BtnContainer = styled.div`
  @media ${(p) => p.theme.deviceSize.desktop} {
    padding: 24px 40px;
  }
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 8px;
  bottom: 0;
  padding: 16px;
  position: sticky;
  background-color: ${(p) => p.theme.color.white1};
`
