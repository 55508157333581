import { makeAutoObservable } from 'mobx'
import Cookies from 'js-cookie'
import jwt from 'jsonwebtoken'
import {
  COOKIE_DOMAIN,
  COOKIE_KEY_STARTUP,
  COOKIE_KEY_USER,
} from 'infra/constants'
import { analytics } from 'infra/analytics'
import { StartupUserTokenPayload, UserTokenPayload } from 'types/common'

const convertLegacyCreated = (createdAt: string) => {
  return Math.floor(new Date(createdAt).getTime() / 1000)
}

export class LoginStore {
  // NOTE(gogo): 이거 직접 쓰지 말고 반드시 useIsLoggedIn 훅을 사용!
  _isLoggedIn: boolean = false
  _isLoggedInStartup: boolean = false
  userCreatedAt: number = 0
  startupCreatedAt: number = 0
  isModalOpen: boolean = false
  isFake: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setIsModalOpen(bool: boolean) {
    this.isModalOpen = bool
  }

  blockMultiLogin() {
    if (!this._isLoggedIn || !this._isLoggedInStartup) return
    this.userCreatedAt > this.startupCreatedAt
      ? this.logoutStartup()
      : this.logout()
  }

  verify() {
    const token = Cookies.get(COOKIE_KEY_USER)
    if (!token) {
      this.logout()
      return
    }
    const payload = jwt.decode(token) as UserTokenPayload | null
    if (!payload) {
      this.logout()
      return
    }
    this.userCreatedAt =
      payload.iat ||
      (payload.created_at ? convertLegacyCreated(payload.created_at) : 0)
    this.isFake = payload.is_fake
    const clockTimestamp = Math.floor(Date.now() / 1000)
    if (clockTimestamp >= payload.exp) {
      this.logout()
      return
    }
    this._isLoggedIn = true
    analytics.setUser(payload)
  }

  logout() {
    Cookies.remove(COOKIE_KEY_USER, { path: '/', domain: COOKIE_DOMAIN })
    this._isLoggedIn = false
    analytics.clearUser()
  }

  verifyStartup() {
    const token = Cookies.get(COOKIE_KEY_STARTUP)
    if (!token) {
      this.logoutStartup()
      return
    }
    const payload = jwt.decode(token) as StartupUserTokenPayload | null
    if (!payload) {
      this.logoutStartup()
      return
    }
    this.startupCreatedAt =
      payload.iat ||
      (payload.created_at ? convertLegacyCreated(payload.created_at) : 0)
    this.isFake = payload.is_fake
    const clockTimestamp = Math.floor(Date.now() / 1000)
    if (clockTimestamp >= payload.exp) {
      this.logoutStartup()
      return
    }
    this._isLoggedInStartup = true
    analytics.setStartupUser(payload)
    if (!payload.is_fake && !payload.is_admin) analytics.startSessionReplay()
  }

  logoutStartup() {
    Cookies.remove(COOKIE_KEY_STARTUP, { path: '/', domain: COOKIE_DOMAIN })
    this._isLoggedInStartup = false
    analytics.clearStartupUser()
  }
}
