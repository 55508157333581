import { makeAutoObservable, reaction } from 'mobx'
import {
  BaseInfo,
  Location,
  PositionFilterType,
  PositionType,
  StartupPositionOrderByType,
  WithIdName,
} from 'types/common'
import { getFitTechStacks } from 'utils/formatters'
import qs from 'query-string'
import { POSITION_FILTER_QUERY_PARAMS_KEY } from 'infra/constants'

export class PositionFilterStore {
  parentPositionTypes: number[] = []
  positionTypes: number[] = []
  careerTypes: number[] = []
  locations: number[] = []
  parentLocations: number[] = []
  techStacks: number[] = []
  resetScroll: boolean = false
  orderBy: StartupPositionOrderByType = '-updatedAt'
  constructor() {
    makeAutoObservable(this)
    reaction(
      () => [
        this.parentPositionTypes.length,
        this.positionTypes.length,
        this.careerTypes.length,
        this.locations.length,
        this.parentLocations.length,
        this.techStacks.length,
        this.orderBy,
      ],
      () => {
        const params = qs.stringify({
          parentPositionTypes: this.parentPositionTypes,
          positionTypes: this.positionTypes,
          careerTypes: this.careerTypes,
          locations: this.locations,
          parentLocations: this.parentLocations,
          techStacks: this.techStacks,
        })
        const baseUrl = window.location.origin + window.location.pathname
        const wholeUrl = `${baseUrl}?${params}`
        localStorage.setItem(POSITION_FILTER_QUERY_PARAMS_KEY, params)
        window.history.replaceState({}, '', wholeUrl)
        window.dispatchEvent(new Event('popstate')) // window와 nextjs의 라우팅을 동기화 하기
      },
    )
  }

  get isEmpty() {
    return [
      this.positionTypes,
      this.careerTypes,
      this.locations,
      this.techStacks,
    ].every((x) => x.length === 0)
  }

  updateFilterState = (
    key: PositionFilterType,
    values?: number[] | number | string[] | string,
  ) => {
    if (typeof values === 'number') {
      this.setState(key, [values])
    } else if (Array.isArray(values)) {
      this.setState(key, values.map(Number))
    } else if (typeof values === 'string') {
      this.setState(key, [Number(values)])
    }
  }

  setState<K extends keyof this>(key: K, value: this[K]) {
    this[key] = value
  }

  setResetScroll(bool: boolean) {
    this.resetScroll = bool
  }

  setPositionTypes(ids: number[]) {
    this.positionTypes = ids
    this.setResetScroll(true)
  }

  setParentPositionTypes(ids: number[]) {
    this.parentPositionTypes = ids
  }

  toggleParentPositionType(id: number) {
    if (this.parentPositionTypes.includes(id)) {
      this.parentPositionTypes = this.parentPositionTypes.filter(
        (_id) => _id !== id,
      )
    } else {
      this.parentPositionTypes = [...this.parentPositionTypes, id]
    }
  }

  removeInvalidTechStacks(baseInfo?: BaseInfo) {
    if (!baseInfo) return
    const filteredTechStacks = new Set(
      getFitTechStacks(this.positionTypes, baseInfo.techStacks).map(
        (x) => x.id,
      ),
    )
    this.setTechStacks(this.techStacks.filter((x) => filteredTechStacks.has(x)))
  }

  toggleLocation(id: number) {
    if (this.locations.includes(id)) {
      this.locations = this.locations.filter((_id) => _id !== id)
    } else {
      this.locations = [...this.locations, id]
    }
    this.setResetScroll(true)
  }

  setParentLocations(ids: number[]) {
    this.parentLocations = ids
  }

  toggleParentLocation(id: number) {
    if (this.parentLocations.includes(id)) {
      this.parentLocations = this.parentLocations.filter((_id) => _id !== id)
    } else {
      this.parentLocations = [...this.parentLocations, id]
    }
  }

  toggleTechStack(id: number) {
    if (this.techStacks.includes(id)) {
      this.techStacks = this.techStacks.filter((_id) => _id !== id)
    } else {
      this.techStacks = [...this.techStacks, id]
    }
    this.setResetScroll(true)
  }

  resetCareerTypes() {
    this.careerTypes = []
  }

  toggleCareerTypes(id: number) {
    if (this.careerTypes.includes(id)) {
      this.careerTypes = this.careerTypes.filter((_id) => _id !== id)
    } else {
      this.careerTypes = [...this.careerTypes, id]
    }
    this.setResetScroll(true)
  }

  togglePositionTypes(id: number) {
    if (this.positionTypes.includes(id)) {
      this.positionTypes = this.positionTypes.filter((_id) => _id !== id)
    } else {
      this.positionTypes = [...this.positionTypes, id]
    }
    this.setResetScroll(true)
  }

  setLocations(ids: number[]) {
    this.locations = ids
    this.setResetScroll(true)
  }

  setTechStacks(ids: number[]) {
    this.techStacks = ids
    this.setResetScroll(true)
  }

  getSelectedValue(id: number[], data: WithIdName[]) {
    return data.filter((item) => id.includes(item.id)).map((item) => item.name)
  }

  getSelectedData(id: number[], data: WithIdName[]) {
    return data.filter((item) => id.includes(item.id))
  }

  getSelectedPositionTypes(id: number[], data: PositionType[]) {
    return data.filter((item) => id.includes(item.id))
  }

  getSelectedLocations(id: number[], data: Location[]) {
    return data.filter((item) => id.includes(item.id))
  }
}
